import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Avatar,
  Flex,
  Stack,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ListModal } from "../Components/ListModal";
import { LandingRight } from "../Components/LandingRight";
import { SpaceLikes } from "../Components/spaceLike";
import { IoIosArrowForward } from "react-icons/io";
import {Helmet, HelmetProvider} from "react-helmet-async";


const getData = ({ slug }) => {
  return axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${slug}`);
};

export const CollectionSinglepage = () => {
  let { slug } = useParams();
  const [pageName, setPageName] = useState("");
  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [loading, setloading] = useState(true);
  let [data, setdata] = useState([]);
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    getData({ slug }).then((res) => {
      setdata(res.data.data);
      setloading(false);
    });
  }, [slug]);


  let metaTitle = ''; // Initialize metaTitle outside the useEffect block
  let metaDescription = ''; // Initialize metaDescription outside the useEffect block

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  useEffect(() => {
    if (data.length > 0 && data[0]?.spaceID?.space_name) {
      const spaceDescription = data[0]?.spaceID.description;
      const defaultDescription =
        "Explore a hand-picked collection of AI tools designed to enhance your workflow. Improve efficiency, boost creativity, and achieve your goals with the power of AI.";

      const metaTitle = `${data[0].spaceID.space_name} - Curated AI Tools Collection - AI Zones`;
      document.title = metaTitle;

      let metaDescription = defaultDescription;
      if (spaceDescription && spaceDescription.trim() !== '') {
        metaDescription = `${spaceDescription} - ${defaultDescription}`;
      }
          
    }
  }, [data]);

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/${slug}`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection/${slug}`} />
    </Helmet>

    <Box bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}>
      <Flex marginTop={{ base: "0px", md: "60px" }}>
        <Stack justifyContent="center" w="100%">
          <Box width="90%" m="auto" mt="0px">
            <Flex
              mt="13px"
              mb="25px"
              alignItems="center"
              fontSize="12px"
              fontWeight="400"
              lineHeight="20px"
            >
              <Link to="/">
                <Text>Home</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/collection">
                <Text>Collection</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Text>{data[0]?.spaceID.space_name}</Text>
            </Flex>

            <Flex
              mt="40px"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Text fontSize="25px" fontWeight="600" lineHeight="24px">
                {data[0]?.spaceID.space_name}
              </Text>

              <SpaceLikes el={data[0]?.spaceID} />
            </Flex>

            <Box
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              py={1}
              paddingBottom="20px"
            >
              <Flex mt="10px" gap="5px" alignItems="center">
              <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}  src={data[0]?.userID.image} />
               
                <Text>{data[0]?.userID.name}</Text>
              </Flex>

              <Text
                mt="30px"
                fontSize="15px"
                lineHeight="24px"
                fontWeight="400"
                textAlign="justify"
              >
                {data[0]?.spaceID.description}
              </Text>

              <Flex w="100%" textAlign="right" justifyContent="right">
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color="#3B89B6"
                >
                  {" "}
                  {data[0]?.spaceID.likes} People have liked this collection
                </Text>
              </Flex>
            </Box>

            <Box mt="25px">
              {data?.map((el, i) => (
                <Box key={i}>
                  <ListModal
                    el={el.videoID}
                    setcat={setcat}
                    setPageName={setPageName}
                    setFilterLoader={setFilterLoader}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
        <Box
            sx={{
              display: 'block',
              '@media (max-width: 1500px)': {
                display: 'none',
              },
            }}
          >
            <LandingRight />
          </Box> 
        
      </Flex>
    </Box>
    </HelmetProvider>
  );
};
