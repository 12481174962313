import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const RedirectPage = () => {
  const { id } = useParams(); // Extract the document ID from the URL
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFinalURL = async () => {
      try {
        // Call the backend to get the final URL
        const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/redirect`);
        const { finalURL } = response.data;

        if (finalURL) {
          window.location.href = finalURL; // Redirect to the final URL
        } else {
          navigate("/not-found"); // Redirect to a fallback page if the URL is not available
        }
      } catch (error) {
        console.error("Error fetching final URL:", error);
        navigate("/error"); // Redirect to an error page
      }
    };

    fetchFinalURL();
  }, [id, navigate]);

  return <div>Redirecting...</div>; // Placeholder UI while redirection is in progress
};

export default RedirectPage;
