// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' for React 18 and above
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from './Redux/store';
import App from './App';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

const defaultTheme = createTheme();

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' 
        ? 'linear-gradient(to right, #222222, #303030)' // dark mode linear gradient
        : 'linear-gradient(to right, #F5F9FD, #F5F9FD )', // light mode linear gradient
        color: props.colorMode === 'dark' ? '#eeeeee' : '#222222', // Adjust the font color here
      },
    }),
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
      
    <Emotion10ThemeProvider theme={defaultTheme}>
      <ChakraProvider theme={theme}>
       {/* HelmetProvider is now wrapping BrowserRouter */}
       <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          </HelmetProvider>
        <ToastContainer />
      </ChakraProvider>
    </Emotion10ThemeProvider>
   
  </Provider>
);
