import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Avatar,
  Flex,
  Stack,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  HStack,
  VStack,
  Divider,
  Badge,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ListModal } from "../Components/ListModal";
import { LandingRight } from "../Components/LandingRight";
import { SpaceLikes } from "../Components/spaceLike";
import { IoIosArrowForward } from "react-icons/io";
import ShareModel from "../Components/Share";
import { LikeCollectionCard } from "../Components/Collection/LikeCollectionCard";
import {Helmet, HelmetProvider} from "react-helmet-async";

const getData = ({ slug }) => {
  return axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${slug}`);
};
export const LikedCollectionSinglePage = () => {
  let { slug } = useParams();
  const [pageName, setPageName] = useState("");
  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [loading, setloading] = useState(true);
  let [data, setdata] = useState([]);
  const { colorMode, toggleColorMode } = useColorMode();

  let url = `${process.env.REACT_APP_PUBLIC_URL}/collection/curated-collection/${data[0]?.spaceID.slug}`;
  
  let metaTitle = ''; // Initialize metaTitle outside the useEffect block
  let metaDescription = ''; // Initialize metaDescription outside the useEffect block

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  useEffect(() => {
    if (data.length > 0 && data[0]?.spaceID?.space_name) {
      const spaceDescription = data[0]?.spaceID.description;
      const defaultDescription =
        "Explore a hand-picked collection of AI tools designed to enhance your workflow. Improve efficiency, boost creativity, and achieve your goals with the power of AI.";

      const metaTitle = `${data[0].spaceID.space_name} - Liked AI Tools Collection - AI Zones`;
      document.title = metaTitle;

      let metaDescription = defaultDescription;
      if (spaceDescription && spaceDescription.trim() !== '') {
        metaDescription = `${spaceDescription} - ${defaultDescription}`;
      }          
    }
  }, [data]);

  useEffect(() => {
    loadAllData(slug);
  }, [slug]);
  
  const loadAllData = async (slug) => {
    let page = 1;
    let hasMorePages = true;
    let allData = [];
  
    while (hasMorePages) {
      const response = await axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${slug}?sort=&page=${page}`);
      const newItems = response.data.data;
  
      if (newItems.length > 0) {
        allData = [...allData, ...newItems];
        page++;
      } else {
        hasMorePages = false;
      }
    }
  
    setdata(allData);
    setloading(false);
  };

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/liked-collection/${slug}`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection/liked-collection/${slug}`} />
   
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/liked-collection/${slug}`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content= "../Utils/AI-Tools-Collections-Coverimg-AIZones.png" />

   
    </Helmet>
    <Box  minH="100vh">
        <Flex mt="10px" mx="auto" w="95%">
          {/* Main Content Area */}
          <Stack flex="1" px={{ base: 4, md: 8}}>
            {/* Hero Section */}
            <Box 
              // bg={useColorModeValue("white", "#222222")}
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              py={5}
              mt={5}
              borderRadius="lg"
              
              
            >
              {/* Breadcrumb */}
              <Breadcrumb 
                spacing="8px" 
                separator={<IoIosArrowForward color="gray.500" />}
                mb={8}
                fontSize="sm"
                color={useColorModeValue("gray.600", "gray.400")}
              >
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/collection">Collection</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/collection">Curated Collections</Link>
                </BreadcrumbItem>
              </Breadcrumb>

              {/* Collection Header */}
              <VStack spacing={6} align="stretch">
                <Heading 
                  as="h1"
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color={useColorModeValue("gray.900", "white")}
                >
                  {data[0]?.spaceID.space_name}
                </Heading>

                {/* Author and Stats */}
                <Flex 
                  justify="space-between" 
                  align="center"
                  bg={useColorModeValue("gray.50", "gray.700")}
                  p={{ base: 3, md: 4 }}
                  rounded="lg"
                >
                  <HStack spacing={{ base: 2, md: 4 }}>
                    <Avatar 
                      src={data[0]?.userID?.image}
                      size={{ base: "xs", md: "md" }}
                      name={data[0]?.userID?.name}
                    />
                    <VStack align="start" spacing={0}>
                      <Text 
                        fontSize={{ base: "xs", md: "sm" }} 
                        color={useColorModeValue("gray.600", "gray.400")}
                      >
                        Created by
                      </Text>
                      <Text 
                        fontSize={{ base: "sm", md: "md" }}
                        fontWeight="medium"
                      >
                        {data[0]?.userID?.name}
                      </Text>
                    </VStack>
                    <Divider 
                      orientation="vertical" 
                      h={{ base: "30px", md: "40px" }}
                    />
                    <Badge 
                      colorScheme="blue" 
                      fontSize={{ base: "xs", md: "sm" }}
                      px={{ base: 2, md: 3 }}
                      py={1}
                      rounded="full"
                    >
                      {data[0]?.spaceID.tool.length} tools
                    </Badge>
                  </HStack>
                  <SpaceLikes el={data[0]?.spaceID} />
                </Flex>

                {/* Description */}
                <Box
                  // bg={useColorModeValue("white", "#222222")}
                  p={3}
                  rounded="lg"
                  border="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                >
                  <Text
                    fontSize="md"
                    lineHeight="tall"
                    color={useColorModeValue("gray.700", "gray.300")}
                  >
                    {data[0]?.spaceID.description}
                  </Text>

                  <HStack 
                    justify="space-between" 
                    mt={6}
                    pt={6}
                    borderTop="1px"
                    borderColor={useColorModeValue("#e0e0e0", "#444")}
                  >
                    <Text
                      fontSize="sm"
                      color="blue.500"
                      fontWeight="medium"
                    >
                      {data[0]?.spaceID.likes} People have liked this collection
                    </Text>
                    <ShareModel url={url} ShareText="Share" />
                  </HStack>
                </Box>
              </VStack>
            </Box>

            {/* Tools List */}
            <Box py={4}>
              <Flex flexDirection="column" gap="20px">
                {data?.map((el, i) => (
                  <LikeCollectionCard
                   
                    key={i}
                  el={el.videoID}
                    setcat={setcat}
                    setPageName={setPageName}
                    setFilterLoader={setFilterLoader}
                  />
                ))}
              </Flex>
            </Box>
          </Stack>

          {/* Right Sidebar - LandingRight */}
          <Box mt="70px" ml="20px"
            sx={{
              display: 'block',
              '@media (max-width: 1500px)': {
                display: 'none',
              },
            }}
          >
            <LandingRight />
          </Box> 
        </Flex>
      </Box>
    </HelmetProvider>
  );
};
