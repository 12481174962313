// import { Box } from "@mui/material";
// import React from "react";
// import { CircularProgress, Stack } from "@chakra-ui/react";
// import { ListModal } from "../ListModal";
// import LoadingAnimation from '../LoadingAnimation';

// export const LIstView = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
// }) => {
 
//   return (
//     <Stack>
//       <Box>
//         {data
//           ?.filter((e) => e.verify)
//           .map((el, i) => (
//             <Box key={i}>
//               <ListModal
//                 el={el}
//                 i={i}
//                 setcat={setcat}
//                 setPageName={setPageName}
//                 setFilterLoader={setFilterLoader}
//               />
//             </Box>
//           ))}
//       </Box>

//       {showLoader ? (
//         <Box
//           height="100px"
//           w="100%"
//           display={"flex"}
//           alignItems={"center"}
//           justifyContent={"center"}
//         >
//           <LoadingAnimation size="xl"/>
//         </Box>
//       ) : null}
//     </Stack>
//   );
// };


























import { Box } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Stack, Flex, Text, useColorModeValue, Image, useBreakpointValue } from "@chakra-ui/react";
import { ListModal } from "../ListModal";
import LoadingAnimation from "../LoadingAnimation";
import axios from "axios";
import style from "../../Style/List.module.css";
import { useLocation } from "react-router-dom";

export const LIstView = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
}) => {
  const [sponsoredTools, setSponsoredTools] = useState([]); // Store all active sponsored tools
  const currentAdIndex = useRef(0); // Ref to track the current ad index
  const bg = useColorModeValue("#ffffff", "#222222");
  const font = useColorModeValue("grey.700", "grey.400");
  const heading = useColorModeValue("grey.800", "#eeeeee");
  const location = useLocation(); // Get the current location

  // Define the number of tools before an ad appears
  const TOOLS_BEFORE_AD = 9;

  // Fetch Sponsored Tools data from backend
  // useEffect(() => {
  //   const fetchSponsoredTools = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
  //       setSponsoredTools(response.data); // Store data in state
  //     } catch (error) {
  //       console.error("Error fetching sponsored tools:", error);
  //     }
  //   };

  //   fetchSponsoredTools();
  // }, []);

      // Fetch Sponsored Tools data from backend (skip if on home page)
      useEffect(() => {
        if (location.pathname !== "/") {
          const fetchSponsoredTools = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
              setSponsoredTools(response.data); // Store data in state
            } catch (error) {
              console.error("Error fetching sponsored tools:", error);
            }
          };
    
          fetchSponsoredTools();
        }
      }, [location.pathname]);

  // Get the next ad to display
  const getNextAd = () => {
    if (sponsoredTools.length === 0) return null; // No ads available
    const ad = sponsoredTools[currentAdIndex.current];
    currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length; // Move to the next ad or restart
    return ad;
  };

  // Function to handle click on sponsored tool URL
  const handleSponsoredToolClick = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
    } catch (error) {
      console.error("Error incrementing click count:", error);
    }
  };

  // Function to handle impression of sponsored tool
  const handleSponsoredToolImpression = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
    } catch (error) {
      console.error("Error incrementing impressions count:", error);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack>
      <Box>
        {data
          ?.filter((e) => e.verify)
          .map((el, i) => (
            <React.Fragment >
              {/* Render Tool */}
              <Box key={i}>
                <ListModal
                  el={el}
                  i={i}
                  setcat={setcat}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                />
              </Box>

              {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools */}
              {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && location.pathname !== "/" && (
                (() => {
                  const adTool = getNextAd(); // Get the next ad to display
                  return (
                    adTool && (
                      <Box
                        as="a"
                        href={adTool.finalURL} // Dynamic URL
                        target="_blank"
                        
                        position="relative"
                        display="flex" // Make it a flexbox
                        alignItems="center" // Center content vertically
                        justifyContent="space-between" // Spread content horizontally
                        border="1px solid"
                        borderColor={font}
                        borderRadius="8px"
                        padding="16px"
                        bg={bg}
                        marginBottom="20px"
                        boxShadow="md"
                        style={{
                          gridColumn: "1 / -1", // Span full width of the grid
                          width: "100%",
                          background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
                        }}
                        _hover={{ boxShadow: "lg" }}
                        _focus={{ boxShadow: "lg" }}
                        onClick={() => handleSponsoredToolClick(adTool._id)}
                        onLoad={() => handleSponsoredToolImpression(adTool._id)}
                      >
                        {/* Gradient Highlight */}
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          bottom="0"
                          width="4px"
                          bg="linear-gradient(to bottom, lightblue, pink)"
                          borderRadius="8px 0 0 8px"
                        />

                        {/* Sponsored Label */}
                        <Box
                          position="absolute"
                          top="8px"
                          right="8px"
                          background="rgba(0, 0, 0, 0.7)"
                          color={heading}
                          fontSize="12px"
                          fontWeight="bold"
                          borderRadius="4px"
                          padding="4px 6px"
                        >
                          Sponsored
                        </Box>

                        {/* Ad Content */}
                        <Flex
                          alignItems="center"
                          gap={4}
                          
                        >
                          {/* Ad Logo */}
                          {/* <img
                            src={adTool.Logo}
                            alt={`${adTool.Title} logo`}
                            style={{
                              height: "80px",
                              width: "80px",
                              borderRadius: "5px",
                              objectFit: "cover",
                            }}
                          /> */}
                          <Image
  src={`${adTool.Logo}?height=80`}
  alt={`${adTool.Title} logo`}
  borderRadius="5px"
  objectFit="cover"
  height={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
  width={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
/>


                          {/* Title and Tagline */}
                          <Flex flexDirection="column" color={heading} flex="1">
                            <Text fontSize="lg" fontWeight="bold">
                              {adTool.Title}
                            </Text>
                            <Text fontSize="sm" color={font}>
                                {isMobile && el?.Tagline
                                  ? `${el.Tagline.substring(0, 70)}...`
                                  : el?.Tagline}
                              </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    )
                  );
                })()
              )}
            </React.Fragment>
          ))}
      </Box>

      {showLoader ? (
        <Box
          height="100px"
          w="100%"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <LoadingAnimation size="xl"/>
        </Box>
      ) : null}
    </Stack>
  );
};
