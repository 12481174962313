import {
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  Image,
  useColorModeValue, UnorderedList, ListItem, Button
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "../Style/Collection.module.css";
import { HashLink } from 'react-router-hash-link';
import { Curated } from "../Components/Collection/Curated";
import { LikeBoard } from "../Components/Collection/like";
import { MyCollection } from "../Components/Collection/MyCollection";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import LoginModel from "../Components/model/LoginModel";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {Helmet, HelmetProvider} from "react-helmet-async";
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import Footer_options from "../Components/Home/Footer_home";

export const Collection = () => {
  const [collectionNavigation, setCollectionNavigation] = useState(
    "Curated Collections"
  );
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const { colorMode, toggleColorMode } = useColorMode();
  const { loginData, isAuth } = useSelector((state) => state.userReducer);


  // loginData
  document.title = "Collections - Curated Solutions at AI Zones";

  // Set meta tags
  const metaTitle = "Explore Top AI Tools: Ultimate Collections for Developers & Creatives - AI Zones";
  const metaDescription = "Dive into our curated collections of AI tools designed to revolutionize productivity, creativity, and efficiency. From AI-driven design enhancements and developer tools to innovative writing assistants and business solutions, find the perfect AI application to elevate your projects and streamline your workflow.";


  const tabMap = {
    '#curatedcollections': 0,
    '#liked': 1,
    '#mycollections': 2,
  };

  // Effect to sync URL hash with tab state
  useEffect(() => {
    const hash = location.hash;
    const index = tabMap[hash];
    if (index !== undefined) {
      setTabIndex(index);
    }
  }, [location.hash, tabMap]);

  // Update the URL hash when a tab is changed
  const handleTabsChange = (index) => {
    const hashKey = Object.keys(tabMap).find(key => tabMap[key] === index);
    window.location.hash = hashKey; // This updates the URL hash
    setTabIndex(index);
  };
  

  const heading = useColorModeValue("gray.800", "white");
  const font = useColorModeValue("gray.600", "gray.400");

  // Add these color mode values for tabs
  const tabBg = useColorModeValue("white", "#1a1b1e");
  const selectedTabBg = useColorModeValue("white", "#1a1b1e");
  const unselectedTabBg = useColorModeValue("gray.50", "#141517");

  // Add these color mode values for the header section
  const breadcrumbColor = useColorModeValue("gray.600", "gray.400");
  const breadcrumbHoverColor = useColorModeValue("gray.800", "white");
  const breadcrumbActiveColor = useColorModeValue("gray.400", "gray.500");
  const headerBg = useColorModeValue("rgba(255,255,255,0.8)", "rgba(26,27,30,0.8)");

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= "https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
    
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />

    
    </Helmet>

     <Stack marginTop={{ base: "60px", md: "60px" }}>
      <Box
        className={style.collection}
        margin="auto"
        fontFamily="Nunito"
        maxW="7xl"
        px={{ base: 4, sm: 6, lg: 8 }}
        py={8}
      >
        <Flex
          mb={6}
          alignItems="center"
          fontSize="sm"
          color={breadcrumbColor}
          spacing={2}
        >
          <Link to="/">
            <Text
              _hover={{ color: breadcrumbHoverColor }}
              transition="color 0.2s"
            >
              Home
            </Text>
          </Link>

          <Text mx={2}>
            <IoIosArrowForward size={12} />
          </Text>

          <Link to="/collection">
            <Text
              _hover={{ color: breadcrumbHoverColor }}
              transition="color 0.2s"
            >
              Collections
            </Text>
          </Link>

          <Text mx={2}>
            <IoIosArrowForward size={12} />
          </Text>

          <Text color={breadcrumbActiveColor}>
            {collectionNavigation}
          </Text>
        </Flex>

        <Box
          bg={headerBg}
          // backdropFilter="blur(10px)"
          borderRadius="2xl"
          p={6}
          mb={8}
          boxShadow="sm"
        >
          <Flex 
            justifyContent="space-between" 
            alignItems="center" 
            mb={6}
          >
            <Flex alignItems="center" gap={4}>
              <Text 
                fontWeight="700" 
                as="h1" 
                fontSize={{ base: "2xl", md: "3xl" }}
                color={heading}
                lineHeight="1.2"
              >
                Collections
              </Text>
              <FontAwesomeIcon
                style={{
                  fontSize: "1.25rem",
                  color: "#3182ce",
                }}
                icon={faBookmark}
              />
            </Flex>

            
          </Flex>

          <Box>
            <UnorderedList 
              textAlign="left" 
              spacing={3}
              styleType="none"
              margin={0}
            >
              <ListItem>
                <Text 
                  fontSize="sm" 
                  color={font}
                  lineHeight="1.6"
                >
                  Explore <Text as="span" fontWeight="600" color={heading}>Curated Collections</Text> to navigate through a public treasury of AI tools, meticulously selected by our community to fuel creativity, enhance productivity, and solve complex challenges.
                </Text>
              </ListItem>
              <ListItem>
                <Text 
                  fontSize="sm" 
                  color={font}
                  lineHeight="1.6"
                >
                  In the <Text as="span" fontWeight="600" color={heading}>Liked</Text> section, find a compilation of top-rated tools and collections endorsed by users like you, showcasing the best in AI-driven design enhancements, developer tools, writing assistants, and more.
                </Text>
              </ListItem>
              <ListItem>
                <Text 
                  fontSize="sm" 
                  color={font}
                  lineHeight="1.6"
                >
                  <Text as="span" fontWeight="600" color={heading}>My Collection</Text> offers a personalized space to assemble your preferred AI applications, from powerful email assistants to generative AI, spreadsheet solutions, and beyond.
                </Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>

        <Tabs 
          index={tabIndex} 
          onChange={handleTabsChange} 
          variant="unstyled"
          mb={6}
        >
          <TabList 
            bg={tabBg}
            p={1}
            borderRadius="full"
            display="inline-flex"
            boxShadow="sm"
          >
            <Tab
              as={HashLink}
              to="#curatedcollections"
              px={6}
              py={2}
              borderRadius="full"
              fontSize="sm"
              fontWeight="500"
              _selected={{
                bg: selectedTabBg,
                color: "blue.500",
                boxShadow: "sm",
              }}
              _hover={{
                color: "blue.400",
              }}
              bg={tabIndex === 0 ? selectedTabBg : unselectedTabBg}
              transition="all 0.2s"
            >
              Curated Collections
            </Tab>
            <Tab
              as={HashLink}
              to="#liked"
              px={6}
              py={2}
              borderRadius="full"
              fontSize="sm"
              fontWeight="500"
              _selected={{
                bg: selectedTabBg,
                color: "blue.500",
                boxShadow: "sm",
              }}
              _hover={{
                color: "blue.400",
              }}
              bg={tabIndex === 1 ? selectedTabBg : unselectedTabBg}
              transition="all 0.2s"
            >
              Liked
            </Tab>
            <Tab
              as={HashLink}
              to="#mycollections"
              px={6}
              py={2}
              borderRadius="full"
              fontSize="sm"
              fontWeight="500"
              _selected={{
                bg: selectedTabBg,
                color: "blue.500",
                boxShadow: "sm",
              }}
              _hover={{
                color: "blue.400",
              }}
              bg={tabIndex === 2 ? selectedTabBg : unselectedTabBg}
              transition="all 0.2s"
            >
              My Collections
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {tabIndex === 0 && <Curated />}
            </TabPanel>
            <TabPanel px={0}>
              {tabIndex === 1 && <LikeBoard />}
              {tabIndex === 1 && !loginData && <LoginModel />}
            </TabPanel>
            <TabPanel px={0}>
              {tabIndex === 2 && <MyCollection />}
              {tabIndex === 2 && !loginData && <LoginModel />}
            </TabPanel>
          </TabPanels>
        </Tabs>
        
      </Box>
    </Stack>
    <Footer_options/>
    </HelmetProvider>
  );
};
