import {
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Spinner,
  Image,
  useColorMode,
  Heading,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import style from "../Style/Tool.module.css";
import { Overall } from "../Components/Top10/Overall";
import { LandingRight } from "../Components/LandingRight";
import { MdOutlineEqualizer } from "react-icons/md";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer_options from "../Components/Home/Footer_home";

export const Top10 = () => {
  // State declarations
  const [data, setData] = useState({});
  const [q, setq] = useState("");
  const [cat, setTabheader] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Color mode and theme values
  const { colorMode } = useColorMode();
  
  // Move ALL useColorModeValue calls to the top level
  const backgroundColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const headingColor = useColorModeValue("gray.900", "white");
  const fontColor = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");
  const boxBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("#e0e0e0", "#444");

  const iconBg = useColorModeValue("blue.50", "blue.900");
  const iconColor = useColorModeValue("blue.600", "blue.200");
  const tabSelectedBg = useColorModeValue("blue.500", "blue.200");
  const tabSelectedColor = useColorModeValue("white", "gray.800");

  // Memoize color values
  const colors = useMemo(() => ({
    backgroundColor,
    textColor,
    headingColor,
    fontColor,
    heading,
    font,
    boxBg,
    borderColor,
    iconBg,
    iconColor,
    tabSelectedBg,
    tabSelectedColor
  }), [backgroundColor, textColor, headingColor, fontColor, heading, font, 
      boxBg, borderColor, iconBg, iconColor, tabSelectedBg, tabSelectedColor]);

  // Memoize logo selection
  const logo = useMemo(() => {
    const lightLogo = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-white.svg";
    const darkLogo = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-black.svg";
    return colorMode === 'light' ? darkLogo : lightLogo;
  }, [colorMode]);

  // Memoize meta content
  const metaContent = useMemo(() => ({
    title: "Unleash the Power of AI: Top 10 Tools Across Every Category - AI Zones",
    description: "Explore our definitive ranking of the top 10 AI tools across Image, Design, Writing, and beyond! Gain insights, compare features, and find the perfect AI champion for your needs."
  }), []);

  // Data fetching effect
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/data/top-ten/category`);
        setTabheader(response.data.map(item => item.title));
        setData(response.data.reduce((acc, item) => ({ 
          ...acc, 
          [item.title]: item.tools 
        }), {}));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array since this should only run once on mount

  // Update document title effect
  useEffect(() => {
    document.title = "Top 10 Tools Across Every Category - AI Zones";
  }, []);

  // Loading state
  if (isLoading) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Image src={logo} boxSize="200px" alt="Logo" />
        <Spinner
          thickness="3px"
          speed="0.90s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/top10`} />
        <meta property="og:title" content={metaContent.title} />
        <meta property="og:description" content={metaContent.description} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content="../Utils/Top-10-AI-Tools-Coverimg-AIZones.png" />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/top10`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/top10`} />
        <meta name="twitter:title" content={metaContent.title} />
        <meta name="twitter:description" content={metaContent.description} />
        <meta name="twitter:image" content="../Utils/Top-10-AI-Tools-Coverimg-AIZones.png" />
      </Helmet>

      <Box minH="100vh" pt={{ base: "40px", md: "60px" }}>
        <Flex 
          maxW={{ base: "100%", xl: "1400px" }} 
          mx="auto" 
          px={{ base: 2, md: 4 }}
          gap={{ base: 2, md: 8 }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Stack 
            flex={1} 
            spacing={{ base: 4, md: 8 }}
            w={{ base: "100%", xl: "100%" }}
          >
            {/* Hero Section */}
            <Box 
              // bg={colors.boxBg}
              p={{ base: 4, md: 8 }}
              borderRadius="xl"
              boxShadow="sm"
              border="1px solid"
              borderColor={colors.borderColor}
              mx={{ base: 2, md: 0 }}
            >
              <Flex 
                alignItems="center" 
                gap={3}
                mb={6}
              >
                <Box
                  p={3}
                  bg={colors.iconBg}
                  borderRadius="lg"
                >
                  <MdOutlineEqualizer size={24} color={colors.iconColor} />
                </Box>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color={colors.headingColor}
                  as="h1"
                >
                  Top 10 AI Tools of 2025
                </Text>
              </Flex>

              <Text
                fontSize="lg"
                color={colors.fontColor}
                as="h2"
                lineHeight="tall"
              >
                Explore the Best AI Tools in Design, Development, Writing, and More - Your Ultimate Guide to the Top-Rated AI Applications Shaping the Future of Technology.
              </Text>
            </Box>

            {/* Tabs Section */}
            <Box
              // bg={colors.boxBg}
              borderRadius="xl"
              boxShadow="sm"
              border="1px solid"
              borderColor={colors.borderColor}
              overflow="hidden"
              mx={{ base: 2, md: 0 }}
            >
              <Tabs variant="soft-rounded" colorScheme="blue">
                <Box 
                  p={{ base: 2, md: 4 }} 
                  borderBottom="1px solid" 
                  borderColor={colors.borderColor}
                >
 <TabList overflow="auto" className={style.tscroll}>
                    {cat?.map((categoryTitle) => (
                      <Tab
                        key={categoryTitle}
                        fontSize="sm"
                        fontWeight="600"
                        px={4}
                        py={2}
                        color={colors.font} 
                        _selected={{
                          bg: colors.tabSelectedBg,
                          color: colors.tabSelectedColor
                        }}
                        onClick={() => setq(categoryTitle)}
                      >
                        {categoryTitle}
                      </Tab>
                    ))}
                  </TabList>
                </Box>

                <TabPanels>
                  {cat.map((categoryTitle, i) => (
                    <TabPanel 
                      key={i} 
                      px={{ base: 2, md: 4 }}
                      py={{ base: 3, md: 4 }}
                    >
                      <Overall data={data[categoryTitle]} />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>

            {/* Info Section */}
            <Box
              p={{ base: 4, md: 8 }}
              rounded="xl"
              bg={colors.boxBg}
              border="1px solid"
              borderColor={colors.borderColor}
              boxShadow="sm"
              mx={{ base: 2, md: 0 }}
            >
              <Heading as="h2" size="md" fontWeight="bold" mb={3} textAlign="left" color={colors.headingColor}>
                Top 10 AI Tools of 2025: Leaders in Innovation Across Categories
              </Heading>
              <Text fontSize="md" as="h3"color={colors.textColor} textAlign="justify">
                Welcome to the "Top 10 AI Tools of 2025: Leaders in Innovation Across Categories" on AI Zones, your ultimate destination for exploring the forefront of AI tool innovation. In a world where efficiency, creativity, and smart solutions are paramount, our curated list of top AI tools spans various categories, ensuring you find the perfect tool to enhance your projects and workflow:
              </Text>
              <UnorderedList textAlign="left" ml={8} my={4}>
                <ListItem>
                  <Text fontSize="md" py={2}color={colors.textColor}>
                    <strong>Design and Development:</strong> Leverage AI for unparalleled productivity, with tools from advanced coding assistants to AI-driven design enhancements.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" py={2} color={colors.textColor}>
                    <strong>Writing and Content Generation:</strong> Discover writing assistants and generative AI that transform how you create content.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" py={2} color={colors.textColor}>
                    <strong>Business Intelligence Solutions:</strong> Utilize AI tools to gain insights and streamline your workflow, from email assistants to spreadsheet solutions.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" py={2} color={colors.textColor}>
                    <strong>Creative Enhancements:</strong> Unleash your creativity with tools for video and audio editing, bringing your visual concepts to life.
                  </Text>
                </ListItem>
              </UnorderedList>
              <Text fontSize="md" color={colors.textColor} textAlign="justify">
                Dive into our comprehensive guide and explore the best AI tools in the market. With AI Zones, navigating the vast landscape of AI tools has never been easier. Join us on a journey to discover AI's eclectic edge, where variety meets innovation, and unlock the power of AI to transform your projects.
              </Text>
            </Box>
          </Stack>

          {/* Right Sidebar */}
          <Box 
            display={{ base: "none", xl: "block" }}
            w={{ xl: "300px" }}
            flexShrink={0}
            position="sticky"
            top="80px"
          >
            <LandingRight />
          </Box>
        </Flex>
        <Footer_options/>
      </Box>
    </HelmetProvider>
  );
};
