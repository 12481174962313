import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@mui/material";
import React, { useState, useRef } from "react";
import axios from "axios";
import { MdOutlineCategory } from "react-icons/md";
import { useSelector } from "react-redux";
import { Button as ChakraButton } from "@chakra-ui/react";

export const BCategory = ({ setcat, setPageName, setFilterLoader, count,
setUserInteracted,
  currentSort, }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { categories } = useSelector((state) => state.userReducer);
  const categoriesFetchedRef = useRef(false);
  const categoriesDataRef = useRef(null);
  const [cat, setcatlist] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  
  const handleCategoryClick = async () => {
    // If we have already fetched the categories data, don't fetch it again.
    if (categoriesFetchedRef.current) {
      console.log("Using cached categories data");
      setcatlist(categoriesDataRef.current);
      return;
    }

    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/cat/get`);
      categoriesDataRef.current = res.data; // Store fetched data in the ref
      setcatlist(res.data); // Update state to re-render with new data
      categoriesFetchedRef.current = true; // Indicate that data has been fetched
      console.log("Fetched categories data: ", res.data);
    } catch (error) {
      // Handle the error appropriately
      console.error("Error fetching categories:", error);
    }
  };

  const handleOpenAndFetch = async () => {
    onOpen(); // This will open the drawer
    // Then fetch categories if they haven't been fetched yet
    await handleCategoryClick(); 
    setUserInteracted(true);// Make sure to use `await` if handleCategoryClick is async
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"} borderRadius="9px">
        <DrawerOverlay />
        <DrawerContent bgGradient={useColorModeValue(
      "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
      "linear(to right, #222222, #303030)" // dark mode grey gradient
       )}  >
          <DrawerHeader
            h="45px"
            borderBottom="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            bg={useColorModeValue("#ffffff", "#222222")}
          >
            <Flex
              textTransform="uppercase"
              w="100%"
              m="auto"
              alignItems="center"
              gap="5px"
              fontSize="14px"
              lineHeight="24px"
              textAlign="left"
              // position="relative"
            >
              <MdOutlineCategory /> Category
              <div
                style={{
                  borderRadius: "50%", // Create a circular shape
                  backgroundColor: "#3B89B6", // Background color
                  color: "white", // Text color
                  width: "24px", // Set the width of the circle
                  height: "24px", // Set the height of the circle
                  display: "flex", // Make it a flex container
                  alignItems: "center", // Center the content vertically
                  justifyContent: "center", // Center the content horizontally
                  // position: "absolute",
                }}
              >
                {categories.length || 0}
              </div>
              {categories.length > 0 && (
                <button
                  color="black"
                  onMouseEnter={() => {
                    var x = document.querySelectorAll(`input[attr=chk]`);
                    for (var i = 0; i < x.length; i++) {
                      x[i].checked = false;
                      setUserInteracted(false);
                      // setIsChecked(true);
                      // setCount(null)
                      // console.log(data);
                    }
                    window.location.reload();
                  }}
                  style={{
                    background: "#ffffff",
                    fontSize: "15px",
                    paddingInline: "5px",
                    borderRadius: "5px",
                    color: "black",   
                    px: "5px",
                    marginLeft :"9px",
                    border: "1px",              
                    cursor: "pointer",
                    outline: "none",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add shadow here                           
                 }}
                >
                 Clear
                </button>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Box>
              <Tabs
                bgScheme=""
                display="flex"
                justifyContent="space-between"
                w="100%"
                border="none"
              >
                <TabList
                  display="flex"
                  fontWeight="600"
                  flexDirection="column"
                  gap="20px"
                  w="100%"
                  border="none"
                >
                  {cat?.map((e, inx) => (
                    <Tab
                      key={inx}
                      _selected={{ bg: colorMode === "dark" ? "" : "#F3F5FA" }}
                      fontSize="13px"
                      fontWeight="600"
                      justifyContent="left"
                      border="none"
                    >
                      {e.Title}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels
                  bg={useColorModeValue("#F3F5FA", "")}
                  display="flex"
                  justifyContent="end"
                >
                  {cat?.map((e, inx) => (
                    <TabPanel
                      w="190px"
                      display="flex"
                      gap="20px"
                      flexDirection="column"
                      textAlign="center"
                      key={inx}
                    >
                      {e.Category?.map((el, i) => (
                        <Flex
                          w="90%"
                          key={i}
                          justifyContent="space-between"
                          alignItems="center"
                          borderBottom="1px solid #e0e0e0"
                          paddingBottom={"10px"}
                        >
                          <Text fontSize="13px" fontWeight="400">
                            {el}
                          </Text>

                          <input
                            value={el}
                            onChange={(e) => {
                              setcat([e.target.value]);
                              setPageName("filter");
                              setFilterLoader(true);
                            }}
                            type="checkbox"
                            style={{
                              borderRadius: 0,
                              borderColor: "black",
                            }}
                          />
                        </Flex>
                      ))}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "40px",
                }}
              >
                <button
                  style={{
                    borderRadius: "5px",
                    borderColor: "#707070",
                    borderWidth: "1px",
                    padding: "5px 20px",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Apply
                </button>
                <button
                  style={{
                    borderRadius: "5px",
                    borderColor: "#707070",
                    borderWidth: "1px",
                    padding: "5px 20px",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Cancel
                </button>
              </div> */}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <ChakraButton
      // w="50%"
      alignItems="center"
      gap="5px"
      fontSize="md"
      lineHeight="24px"
      textAlign="center"
      w="150px"
      justifyContent="center"
      onClick={handleOpenAndFetch}
      borderRadius={10}
      variant="solid" // Style as needed
      bg={useColorModeValue("#ffffff", "#222222")}   
       border="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} 
       boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)" 
    >
       <MdOutlineCategory />
<Text>Category</Text>
{categories.length > 0 && (
  <Box
    borderRadius="50%"
    backgroundColor="#3B89B6"
    color="white"
    width="24px"
    height="24px"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {categories.length}
  </Box>
)}
</ChakraButton>
    </>
  );
};
