import React, { useEffect, useRef, useState } from "react";
import style from "../Style/Navbar.module.css";
// import logo from "../Utils/LOGO-AIZONES-white.svg";
import LoadingAnimation from './LoadingAnimation';
import {
  Box,
  Flex,
  Button,
  useColorMode,
  Input,
  IconButton,
  Icon,
  useDisclosure,
  Avatar,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  InputLeftElement,
  InputGroup,
  Divider,
  InputRightElement,
  Switch,
  Image,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import {
  CloseIcon,
  HamburgerIcon,
  MoonIcon,
  Search2Icon,
  SunIcon,
} from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BiTrendingUp } from "react-icons/bi";
import dstyle from "../Style/Featured.module.css";
import { RiShareBoxFill } from "react-icons/ri";
import { CurtedCollection } from "./CurtedCollection";
import { IconandPrivacy } from "./IconandPrivact";
import SearchBarSmallScreen from "../Components/SearchBarSmallScreen";
import { SearchClient as TypesenseSearchClient } from "typesense";
import { searchItem } from "../Redux/action";
import LoginModel from "./model/LoginModel";
import HandleGoogle from '../Firebase/handleGoogleAuth';
import { extendTheme } from "@chakra-ui/react";
import { LandingRight } from "./LandingRight";

const getData = async ({ input }) => {
  return axios.post(`${process.env.REACT_APP_API}/data/get?search=${input}`);
};
export const Navbar = () => {

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const toggleMobileSearch = () => {
    setShowMobileSearch(!showMobileSearch);
  };


  const logo = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-white.svg";


  const [tdata, settData] = useState([]);  

  // Move the handleTop10Click function inside the Navbar component

  const [isLoading, setIsLoading] = useState(false);

  const [loginModelOpen, setLoginModelOpen] = useState(false);

  const handleSubmitLink = async () => {
    if (!loginData) {
      // Pass a callback to HandleGoogle to navigate to "/submit" after login
      await HandleGoogle(dispatch, navigate, () => navigate("/submit"));
    } else {
      navigate("/submit");
    }
  };
  
  const handleClose = () => {
    setLoginModelOpen(false);
  }
    

  const reload = ()=> {
    navigate("/")
    window.location.reload()
  }

  const subNavbarHeight = '64px'; // Example height, adjust as needed
  const data = [
    // <Link to="https://updates.aizones.io">Newsletter</Link>,
    <Link to="/updates">Updates</Link>,
    <Link to="/collection">Collection</Link>,
    <Link to="/top10">Top 10</Link>,
    <Link to="/about">About</Link>,
    // <Link to="/top10" onClick={handleTop10Click}>Top 10</Link>,
    // "Blog",
    <Link
      to="https://www.passionfroot.me/ai-zones "
      // "https://aizones.notion.site/aizones/Advertise-with-Us-a170d9dfde6043f49e5ecfaaeb7d15c6 "
      target="_blank"
    >
      {" "}
      Advertise
    </Link>,
      
    // <Link to="/submit">Submit </Link>
  ];
 
  const containerBg = "#4A4A4A"; // This will be the constant background color of the container
  
  const iconColor = { light: "white", dark: "white" };
 

  const phrases = [
    "Discover AI Tools Using AI",
    "Can you suggest tools for digital marketing?",
    "Show me AI tools for marketing",
    "I want help with my emails",
    "I want help in making reels"
  ];
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [placeholderText, setPlaceholderText] = useState(phrases[0]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (placeholderIndex + 1) % phrases.length;
      setPlaceholderIndex(newIndex);
      setPlaceholderText(phrases[newIndex]);
    }, 10000); // Change the interval time (in milliseconds) as needed
  
    return () => clearInterval(interval);
  }, [placeholderIndex, phrases]);
  


  const [pop, setpop] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  let { isAuth, loginData } = useSelector((store) => store.userReducer);
  const [input, setInput] = useState(""); // Initialize input state as null
  const [sdata, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [dlength, setLength] = useState(0);
  // let [tdata, settData] = useState([]);
  let [page, setPage] = useState(1);
  const [showBox, setShowBox] = useState(false);
  // const [mobileSearch, showMobileSearch] = useState(false);
  const [check, setCheck] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

 
 
  const boxRef = useRef(null);

  const creatorPortal = window.localStorage.getItem("editToolID");

  
  const handleInputChange = (e) => {
    setInput(e.target.value); // Update the input state with the new value
    setShowBox(false);

    if (e.key === 'Enter') {
        handleSearchWithCurrentInput(e.target.value); // Trigger search on Enter key press
    }
};

const handleClearInput = () => {
  setInput(""); // Clear the input field
  // Additional actions if needed, like resetting search results
};


  // const handleSearchWithCurrentInput = (inputValue) => {
  //   navigate(`/search?q=${encodeURIComponent(inputValue)}`);
  // };

  const handleSearchWithCurrentInput = (inputValue) => {
    // Convert the input value to a slug (e.g., "AI Image Generators" -> "ai-image-generators")
    const slug = inputValue.toLowerCase().split(" ").join("-");
  
    // Navigate using the slug as part of the path
    navigate(`/search/${slug}`);
  };
  
  
  const handleLogout = () => {
    localStorage.removeItem("UserData");
    setpop(!pop);
    window.location.reload();
  };

 

  const infinitScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []);



const theme = extendTheme({
  breakpoints: {
    sm: '420px',
    md: '768px',
    
    lg: '1024px',
    xl: '1280px',
    mdplus:'1400px',
    '2xl': '1536px',
  },
});
 
  return (
    <>
       {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null}
      <div className={style.navbar}>
        <Box
          position="relative"
          bg={useColorModeValue("#222222", "#222222")}
          px={3}
          color="#FFFFFF"
        >
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <Flex gap="20px" alignItems="center">
              <Link to="/">
                <Box>
                  <Image src={logo} alt="AI Zones Logo" onClick={reload} />
                </Box>
              </Link>
              <Box className={style.linputbox}>
              
              <InputGroup
              className={style.searchbarwidth}           
                width={{
                      base: "100%", // on very small screen (base), use 100% width                      
                     
                      md: "450px", // on medium screen sizes (about 768px), use 312px width
                      mdplus: "450px",       
                      lg: "400px", // on large screen sizes (about 1100px), use 412px width
                                  xl: "500px"  // on extra large screens, use 612px width
                                  }}                                 
                                  >          
              <Input
            id="searchInput"
             value={input}
              onChange={handleInputChange}
               onKeyDown={handleInputChange}
                 bg={useColorModeValue("#F5F9FD", "#1c1c1c")}
                _placeholder={{
               color: "grey",
                fontSize: "15px",
                   lineHeight: "24px",
                 position: "relative",
                    animation: "typing 10s steps(40) infinite",
                   whiteSpace: "nowrap",
                  overflow: "hidden",
                  }}
                  color={useColorModeValue("black", "white")}
                  borderRadius="5px"
               textAlign="center"
               placeholder={placeholderText}
              />
             <InputLeftElement>
           
         {isLoading ? (
      <LoadingAnimation /> // Display the loading animation when isLoading is true
     ) : (
      <IconButton
        aria-label="Search"
        icon={<Search2Icon />}
         onClick={() => {
    const searchInputValue = document.getElementById('searchInput').value;
    handleSearchWithCurrentInput(searchInputValue);
  }}
        variant="ghost"
          iconSpacing={2}
           />
            )}
              </InputLeftElement>
             <InputRightElement>
            {input && (
              <IconButton
                aria-label="Clear"
                icon={<CloseIcon />}
                onClick={handleClearInput}
                variant="ghost"
              />
            )}
            {/* ... existing search button code ... */}
          </InputRightElement>
         </InputGroup>

 
                  {/* <InputRightElement>
                    <Switch size="sm" onChange={toggleChecked} />
                  </InputRightElement> */}
                
              </Box>


            </Flex>
            

            <Flex alignItems="center" gap="25px">
              <Box className={style.ltext}>
                <Flex gap="25px">
                  {data.map((el, i) => (
                    <Text
                      key={i}
                      color="#F8F8F8"
                      fontFamily="'Lato', Arial, Helvetica, sans-serif"
                      fontSize="15px"
                      lineHeight="25px"
                      fontWeight="400"
                    >
                      {el}
                    </Text>
                  ))}
                  {/* /////////////////////////////////////////////////////////////////////// */}
                  <Text cursor={"pointer"} onClick={handleSubmitLink}>
                    Submit
                  </Text>
                </Flex>
              </Box>
              {isAuth ? (
                ""
              ) : (
                <Box className={style.snav} display={{ base: "none", md: "block" }} onClick={() => HandleGoogle(dispatch, navigate, onClose)} cursor="pointer" >
               
                    <Text
                      fontSize="15px"
                      lineHeight="25px"
                      fontWeight="400"
                      color="#F8F8F8"
                    >
                      Sign in
                    </Text>
                
                </Box>
              )}

             {/* <LoadingIndicator isLoading={isLoading} top={subNavbarHeight} /> */}

              <Box className={style.ltext}>
                <Flex gap="25px" alignItems="center">
                  {isAuth ? (
                    <Menu color="#3B89B6">
                      <MenuButton
                        as={Button}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        color="white"
                        minW={0}
                      >
                        <Avatar size={"sm"} src={loginData.image} />
                      </MenuButton>
                      <MenuList zIndex="250">
                        <Box color="#3B89B6">
                          <Flex
                            alignItems="center"
                            w="fit-content"
                            gap="20px"
                            ml="15px"
                          >
                            <Avatar size={"md"} src={loginData.image} />

                            <Text fontSize="15px" fontWeight="400" >
                              {loginData.name}
                            </Text>
                          </Flex>
                        </Box>
                        {/* <Link to="/creator"> <MenuItem color="#3B89B6" mt="15px">Creator's portal</MenuItem></Link> */}
                        {isAuth && loginData.Account_type === 'expert' && (
                         <Link to="/profile_setting">
                         <MenuItem color="#3B89B6">Setting</MenuItem>
                        </Link>
                          )}
                        <Link to="/my-tools">
                          {" "}
                          <MenuItem color="#3B89B6">My Tools</MenuItem>
                        </Link>
                        {creatorPortal && (
                           <a href={`/update-tool/${creatorPortal}`}>
                            <MenuItem color="#3B89B6">
                              Creator’s Portal
                            </MenuItem>
                            </a>
                        )}
                        <MenuItem color="#3B89B6" _hover={{ cursor: 'pointer' }}  onClick={handleLogout}>
                          Sign out
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    // <Link to="/signup">
                      <Button
                        backgroundColor="#3B89B6"
                        color="white"
                        borderRadius="5px"
                        onClick={() => setLoginModelOpen(true)}
                      >
                        Sign up
                      </Button>
                    // </Link>
                  )}
                  {/* {"theme button- light mode and dark mode"} */}
                            <Flex
                              align="center"
                              justify="center"                              
                              p={2}                              
                              w="60px"                              
                              h="30px"                              
                              bg={containerBg}                              
                              borderRadius="full"                              
                              position="relative"                              
                              border="2px solid white" // White border around the container                              
                              _hover={{ cursor: 'pointer' }}                              
                              onClick={toggleColorMode}                             
                              >                              
                            {/* Sun and Moon Icons */}                                    
                                     
                          <Icon                          
                          as={SunIcon}                          
                          color={colorMode === 'light' ? iconColor.light : "transparent"}                          
                          boxSize={3}                          
                          position="absolute"                          
                          left="10px"                          
                          zIndex={2}                         
                          />
                      <Icon                      
                          as={MoonIcon}                          
                          color={colorMode === 'dark' ? iconColor.dark : "transparent"}                          
                          boxSize={3}                          
                          position="absolute"                          
                          right="10px"                          
                          zIndex={2}                          
                          />        
                      </Flex>
                </Flex>
              </Box>

              <Box className={style.iconbtn}>
                <Flex alignItems="center" background="none">
                  <Box
                    display={{ base: "block", md: "flex" }}
                    alignItems="center"
                  >
              <Box
              className={style.searchhide}
              onClick={toggleMobileSearch} // Toggle the search bar visibility
              mr="15px"
              >
              <Search2Icon cursor="pointer" />
               </Box>

                    {/* <Box>
                      <Avatar size={"sm"} src={loginData.image} />
                    </Box> */}
                  </Box>

                  <IconButton
                    ref={btnRef}
                    backgroundColor="#1E1E1E"
                    _hover={{
                      backgroundColor: "#1E1E1E",

                      boxShadow: "none",
                    }}
                    color="white"
                    size="md"
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={isOpen ? "Close menu" : "Open menu"} 
                    onClick={onOpen}
                  />

                  <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    finalFocusRef={btnRef}
                  >
                    <DrawerOverlay />
                    <DrawerContent
                     bgGradient={useColorModeValue(
                     "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
                     "linear(to right, #222222, #303030)" // dark mode grey gradient
                      )}
                    >
                      <DrawerCloseButton />
                      <DrawerHeader>MENU</DrawerHeader>

                      <DrawerBody>
                      <Flex flexDirection="column" gap="20px">
                     {data.map((el, i) => (
                     <Box key={i} width="100%" _hover={{ cursor: "pointer" }}>
                    {React.cloneElement(el, {
                    style: { display: 'block', width: '100%' },
                    onClick: onClose,
                    })}
                    </Box>
                    ))}
                   <Text cursor={"pointer"} onClick={() => {
                   handleSubmitLink();
                   onClose();
                  }}>Submit</Text>
                 </Flex>
                        <Divider
                          border="1px"
                          mt="20px"
                          mb="20px"
                          borderColor={useColorModeValue("#e0e0e0", "#444")}
                        />

                        {isAuth ? (
                          <Box>
                            <Flex
                              mb="10px"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Flex gap="20px" alignItems="center">
                                <Avatar size={"sm"} src={loginData.image} />
                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="400"
                                >
                                  {loginData.name}
                                </Text>
                              </Flex>
                            </Flex>

                            <Flex
                              fontSize="15px"
                              lineHeight="24px"
                              fontWeight="400"
                              flexDirection="column"
                              gap="10px"
                              textAlign="left"
                            >
                              <Link to="creator">
                                <Text onClick={onClose}>Creator's portal</Text>
                              </Link>
                              {isAuth && loginData.Account_type === 'expert' && (
                        
                          
                              <Link to="profile_setting">
                                <Text onClick={onClose}>Setting</Text>
                              </Link> 
                              )}
                              <Text
                              _hover={{ cursor: 'pointer' }} 
                                onClick={() => {
                                  handleLogout();
                                  onClose();
                                }}
                              >
                                Sign out
                              </Text>
                            </Flex>
                          </Box>
                        ) : (
                          <Box>
                            
                              <Text
                                border="1px"
                                textAlign="center"
                                p="10px"
                                fontSize="15px"
                                w="100%"
                                borderRadius="5px"
                                onClick={() => HandleGoogle(dispatch, navigate, onClose)} cursor="pointer"
                              >
                                Sign in
                              </Text>
                            

                            {/* <Link to="/signup"> */}
                              <Text
                                mt="20px"
                                color="white"
                                bg="#3B89B6"
                                fontSize="15px"
                                border="1px"
                                textAlign="center"
                                p="10px"
                                w="100%"
                                borderRadius="5px"
                                onClick={() => {
                                  setLoginModelOpen(true); // Trigger the sign-up model
                                  onClose(); // Close the drawer
                                }}
                                cursor="pointer"
                              >
                                Sign up
                              </Text>
                            {/* </Link> */}
                          </Box>
                        )}
                        <Divider
                          border="1px"
                          mt="20px"
                          mb="20px"
                          borderColor={useColorModeValue("#e0e0e0", "#444")}
                        />

                        {/* {mobile dark mode & light mode} */}

                        <Flex
                              align="center"
                              justify="center"                              
                              p={2}                              
                              w="60px"                              
                              h="30px"                              
                              bg={containerBg}                              
                              borderRadius="full"                              
                              position="relative"                              
                              border="2px solid white" // White border around the container                              
                              _hover={{ cursor: 'pointer' }}                              
                              onClick={toggleColorMode}                             
                              >                              
                            {/* Sun and Moon Icons */}                                    
                                     
                          <Icon                          
                          as={SunIcon}                          
                          color={colorMode === 'light' ? iconColor.light : "transparent"}                          
                          boxSize={3}                          
                          position="absolute"                          
                          left="10px"                          
                          zIndex={2}                         
                          />
                      <Icon                      
                          as={MoonIcon}                          
                          color={colorMode === 'dark' ? iconColor.dark : "transparent"}                          
                          boxSize={3}                          
                          position="absolute"                          
                          right="10px"                          
                          zIndex={2}                          
                          />        
                      </Flex>


                      
                        {/* <Divider
                          border="1px"
                          mt="20px"
                          mb="20px"
                          borderColor={useColorModeValue("#e0e0e0", "#444")}
                        />
                        <IconandPrivacy /> */}
                        <Divider
                          border="1px"
                          mt="20px"
                          mb="20px"
                          borderColor={useColorModeValue("#e0e0e0", "#444")}
                        />
                        <LandingRight/>                        
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>

        {showBox && (
          <Box
            ref={boxRef}
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px"
            maxH="500px"
            position="absolute"
            bg={colorMode === "light" ? "white" : "#2C2C2C"}
            overflow="auto"
            className={style.searchbar}
            p="20px"
          >
            <Box className={style.searchhide} mb="10px">
              <Input
                onChange={(e) => setInput(e.target.value)}
                w="100%"
                borderRadius="5px"
                placeholder={`Search from ${dlength} tools`}
              />
            </Box>

           

            {input.length >= 1
              ? sdata?.map((el, i) => (
                  <Flex
                    key={i}
                    mb="20px"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="15px"
                  >
                    <Link
                      key={i}
                      to={`/tool/${el.slug}`}
                      onClick={() => setShowBox(false)}
                    >
                      <Flex gap="15px" alignItems="center">
                        <Image
                          boxSize="35px"
                          borderRadius="4px"
                          src={`${el?.Logo}?height=35`}
                        />
                        <Box>
                          <Text
                            fontSize="13px"
                            lineHeight="24px"
                            fontWeight="600"
                            color={"initial"}
                          >
                            {el.Title}
                          </Text>
                          <Text
                            fontSize="12px"
                            lineHeight="20px"
                            color={"initial"}
                            className={dstyle.desc}
                          >
                            {el.Tagline}
                          </Text>
                        </Box>
                      </Flex>
                    </Link>
                    <Link to={el.URL} target="_blank">
                      {" "}
                      <RiShareBoxFill size={14} aria-label="View Product Page" color={colorMode === 'dark' ? 'white' : 'black'}/>
                    </Link>
                  </Flex>
                ))
              : ""}
              <Flex justifyContent="center" mt="20px">
             <Text color="gray.500">Press enter to load more</Text>
            </Flex>

            <Box w="100%">
              <Box display="block"></Box>
            </Box>
          </Box>
        )}
     
{showMobileSearch && <SearchBarSmallScreen toggleSearchBar={toggleMobileSearch} />}

      </div>
    </>
  );
};
