import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingAnimation from '../LoadingAnimation';

export const MyCollection = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Color mode values
  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("white", "#222222");
  const borderColorValue = useColorModeValue("gray.200", "gray.700");
  const iconGridBg = useColorModeValue("#DAE7FA", "#2f3f48");
  const cardShadowLight = "0 4px 12px rgba(66, 153, 225, 0.15)";
  const cardShadowDark = "0 4px 12px rgba(66, 153, 225, 0.25)";
  const cardShadow = useColorModeValue(cardShadowLight, cardShadowDark);
  const toolsColor = useColorModeValue("blue.500", "blue.400");

  const userData = useSelector((state) => state.userReducer.loginData);
  let token = userData.data;

  const getData = async (page) => {
    if (loading || !hasMore) return; // Don't fetch if already loading or no more data

    setLoading(true); // Set loading before the request
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/space/get?page=${page}`, {
        headers: { token },
      });

      let fetchedData = res.data.data || [];
      if (fetchedData.length === 0) {
        setHasMore(false); // If no data is returned, we've reached the end
      } else {
        setData((prevData) => [...prevData, ...fetchedData]); // Append new data to the existing data
      }
    } catch (err) {
      // Handle error here
      setHasMore(false); // Assume no more data on error
    }
    setLoading(false); // Set loading after the request is finished
  };

  // Fetch initial data
  useEffect(() => {
    getData(page);
  }, [page]);

  // Infinite scroll logic
  useEffect(() => {
    const infinitScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []);

  // Memoize the card rendering function
  const renderCard = useMemo(() => (el, i) => (
        <Link to={`/collection/my-collection/${el.slug}`} key={i}>
      <Box
        bg={bgColor}
        borderRadius="lg"
        border="1px"
        borderColor={borderColorValue}
        transition="all 0.2s"
                 _hover={{ 
          borderColor: "blue.400",
          transform: "translateY(-2px)",
          boxShadow: cardShadow
        }}
      >
        <Box p={6}>
          <Box
            width="100%"
            bg={iconGridBg}
          borderRadius={5}
            p={4}
            mb={4}
          >
<Flex 
  gap={3} 
  flexWrap="nowrap"
  justifyContent="space-between"
  width="100%"
  overflow="hidden"
>
  {[0, 1, 2, 3, 4, 5].map((index) => (
    el?.tool[index]?.logoURL && (
      <Box
        key={index}
        display={{ base: index > 3 ? 'none' : 'block', md: 'block' }}
        flexShrink={0}  // Prevents icons from shrinking
      >
        <Image
          src={`${el.tool[index].logoURL}?height=30`}
          borderRadius="4px"
          width="40px"
          height="40px"
          objectFit="contain"
          alt={`Tool ${index + 1} logo`}
        />
      </Box>
    )
  ))}
</Flex>
          </Box>

                  <Text
            fontSize="lg"
            fontWeight="600"
                    color={heading}
            mb={2}
            noOfLines={1}
                  >
                    {el?.space_name}
                  </Text>

                    <Text
            color={font}
            fontSize="sm"
            mb={4}
            noOfLines={2}
          >
            {el?.description}
                    </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            borderTop="1px"
            borderColor={borderColorValue}
          >
            <Flex alignItems="center" gap={2}>
              <Avatar
                src={el?.userID?.image}
                size="xs"
              />
              <Text fontSize="sm" color={font}>
                {el?.userID?.name?.length > 15 
                  ? `${el.userID.name.substring(0, 15)}...` 
                  : el.userID.name}
                      </Text>
                    </Flex>
                    <Text
              fontSize="sm"
              color={toolsColor}
            >
              {el?.tool.length} tools
                      </Text>
                  </Flex>
                </Box>
                </Box>
          </Link>
  ), [bgColor, borderColorValue, font, heading, iconGridBg, cardShadow, toolsColor]);

  return (
    <Box fontFamily="'Lato', Arial, Helvetica, sans-serif">
      <Grid
        templateColumns={{ 
          base: "repeat(1, 1fr)", 
          md: "repeat(2, 1fr)", 
          lg: "repeat(3, 1fr)" 
        }}
        gap={6}
      >
        {data?.map(renderCard)}
    </Grid>

      {loading && (
        <Flex justifyContent="center" width="100%" mt={6}>
          <LoadingAnimation size="xl" />
        </Flex>
      )}
    </Box>
  );
};
