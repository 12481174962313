
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Link,
  useColorMode,
  Image,
  useColorModeValue,
  Box,
  VStack,
  HStack,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
// import logodark from "../../Utils/LOGO-AIZONES-black.svg";
// import logolight from "../../Utils/LOGO-AIZONES-white.svg";
import HandleGoogle from '../../Firebase/handleGoogleAuth';
import { useDispatch } from 'react-redux';



export default function LoginModal({ onCloseOuter = () => {} }) {
  const { colorMode } = useColorMode();

const logolight = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-white.svg";
const logodark = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-black.svg";
  const logo = colorMode === "light" ?  logodark : logolight;
  const navigate = useNavigate();
  const finalRef = useRef(null);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
    onCloseOuter();
  };

  return (
    <Modal isOpen={open} onClose={onClose} isCentered motionPreset="slideInBottom" size="xl">
      <ModalOverlay />
      <ModalContent mx={4} borderRadius="lg">
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">
          <Image src={logo} mx="auto" my={4} />
          AI For Everyone
        </ModalHeader>
        <ModalBody textAlign="center">
          <VStack spacing={4} align="stretch" mb={6} >
            <Box>
              <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                Discover AI Tools Using AI
              </Text>
              <Text fontSize="sm">AI Search with Large Language Model Integration.</Text>
            </Box>
            <Box>
              <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                Curated Collections
              </Text>
              <Text fontSize="sm">Explore curated AI tool collections made by other users.</Text>
            </Box>
            <Box>
              <Text fontWeight="semibold" color={useColorModeValue("blue.600", "blue.300")}>
                Create Personalized Collection
              </Text>
              <Text fontSize="sm">Organize your AI tools in one place.</Text>
            </Box>
          </VStack>
          <Button
            leftIcon={<FcGoogle />}
            // colorScheme="red"
            size="lg"
            w="full"
            onClick={() => HandleGoogle(dispatch, navigate, onClose)}
            // variant="outline"
          >
            Sign up / Sign in with Google
          </Button>
          {/* <Text fontSize="sm" mt={4}>
            By signing in, you agree to our <Link color="blue.500" href="/terms" isExternal>Terms of Use</Link> and <Link color="blue.500" href="/privacy" isExternal>Privacy Policy</Link>.
          </Text> */}
           {/* <Text fontSize="sm" mt={4}>
            Already a user? <Link color="blue.500" onClick={() => HandleGoogle(dispatch, navigate, onClose)}>Sign in</Link>
          </Text> */}
          {/* <Text fontSize="sm" mt={4}>
            Register using email <Link color="blue.500" onClick={() => navigate('/login')}>Click here</Link>
          </Text> */}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}





// import React, { useRef, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   Button,
//   Text,
//   Link,
//   useColorMode,
//   Image,
//   useColorModeValue,
//   Box,
//   VStack,
//   useToast,
//   HStack,
//   useBreakpointValue,
//   Flex,
// } from "@chakra-ui/react";
// import { FcGoogle } from "react-icons/fc";
// // import logodark from "../../Utils/LOGO-AIZONES-black.svg";
// // import logolight from "../../Utils/LOGO-AIZONES-white.svg";
// import HandleGoogle from "../../Firebase/handleGoogleAuth";
// import { useDispatch, useSelector } from "react-redux";

// // To track if a modal instance is already open globally
// let isModalOpen = false;

// export default function LoginModal({ onCloseOuter = () => {}, isMandatory = false }) {
//   const { userReducer } = useSelector((store) => store); // To check if the user is logged in
//   const { colorMode } = useColorMode();

//   const logolight = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-white.svg";
// const logodark = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-black.svg";
//   const logo = colorMode === "light" ? logodark : logolight;
//   const navigate = useNavigate();
//   const finalRef = useRef(null);
//   const dispatch = useDispatch();
//   const [open, setOpen] = useState(true); // Local state to manage visibility
//   const [isLoginAttempted, setIsLoginAttempted] = useState(false); // Track if login is attempted
//   const toast = useToast();
//   const colorModeValue = useColorModeValue("blue.600", "blue.300");

//   useEffect(() => {
//     // Prevent multiple instances
//     if (isModalOpen) {
//       setOpen(false);
//       onCloseOuter();
//     } else {
//       isModalOpen = true; // Mark modal as open
//     }

//     return () => {
//       isModalOpen = false; // Mark modal as closed when unmounting
//     };
//   }, [onCloseOuter]);

//   // Close modal automatically if user logs in successfully
//   useEffect(() => {
//     if (userReducer.isAuth && isLoginAttempted) {
//       setOpen(false);
//       onCloseOuter(); // Notify the parent to close the modal
//     }
//   }, [userReducer.isAuth, isLoginAttempted, onCloseOuter]);

//   const onLogin = async () => {
//     setIsLoginAttempted(true); // Mark that login is being attempted
//     HandleGoogle(dispatch, navigate, () => {
//       // Successful login, no notification required here
//     });
//   };

//   const handleClose = () => {
//     if (isMandatory) {
//       // Notify user that login is required
//       toast({
//         title: "Login Required",
//         description: "Please log in to continue.",
//         status: "warning",
//         duration: 3000,
//         isClosable: true,
//         position: "top",
//       });
//     } else {
//       setOpen(false);
//       onCloseOuter();
//     }
//   };

//   if (!open) return null; // Prevent rendering if modal is not open

//   return (
//     <Modal
//       isOpen={open}
//       onClose={handleClose}
//       isCentered
//       motionPreset="slideInBottom"
//       size="xl"
//     >
//       <ModalOverlay />
//       <ModalContent mx={4} borderRadius="lg">
//         <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">
//           <Image src={logo} mx="auto" my={4} alt="AI Zones Logo" />
//           AI For Everyone
//         </ModalHeader>
//         <ModalBody textAlign="center">
//             <VStack spacing={4} align="stretch" mb={6} >
//             <Box>
//               <Text fontWeight="semibold" color={colorModeValue}>
//                 Discover AI Tools Using AI
//               </Text>
//               <Text fontSize="sm">AI Search with Large Language Model Integration.</Text>
//             </Box>
//             <Box>
//               <Text fontWeight="semibold" color={colorModeValue}>
//                 Curated Collections
//               </Text>
//               <Text fontSize="sm">Explore curated AI tool collections made by other users.</Text>
//             </Box>
//             <Box>
//               <Text fontWeight="semibold" color={colorModeValue}>
//                 Create Personalized Collection
//               </Text>
//               <Text fontSize="sm">Organize your AI tools in one place.</Text>
//             </Box>
//           </VStack>
//           <Button
//             leftIcon={<FcGoogle />}
//             size="lg"
//             w="full"
//             borderColor={"blue"}
//             onClick={onLogin}
//           >
//             Sign up / Sign in with Google
//           </Button>
//             {/* <Text fontSize="sm" mt={4}>
//               By signing in, you agree to our <Link color="blue.500" href="/terms" isExternal>Terms of Use</Link> and <Link color="blue.500" href="/privacy" isExternal>Privacy Policy</Link>.
//             </Text> */}
//              {/* <Text fontSize="sm" mt={4}>
//               Already a user? <Link color="blue.500" onClick={() => HandleGoogle(dispatch, navigate, onClose)}>Sign in</Link>
//             </Text> */}
//             {/* <Text fontSize="sm" mt={4}>
//               Register using email <Link color="blue.500" onClick={() => navigate('/login')}>Click here</Link>
//             </Text> */}
//         </ModalBody>
//         <ModalFooter justifyContent="center">
//           <Button onClick={handleClose}>Close</Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// }
