import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingAnimation from '../LoadingAnimation';

export const SpaceLike = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Move color mode values outside of render
  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("white", "#222222");
  const borderColorValue = useColorModeValue("gray.200", "gray.700");
  const hoverBorderColor = useColorModeValue("gray.300", "gray.600");
  const iconGridBg = useColorModeValue("#DAE7FA", "#2f3f48");

  // Add these new color mode values
  const cardShadowLight = "0 4px 12px rgba(66, 153, 225, 0.15)";
  const cardShadowDark = "0 4px 12px rgba(66, 153, 225, 0.25)";
  const cardShadow = useColorModeValue(cardShadowLight, cardShadowDark);
  const toolsColor = useColorModeValue("blue.500", "blue.400");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");

  const userData = useSelector((state) => state.userReducer.loginData);
  let token = userData.data;

  const getData = async (pageNum) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/space/get/space-like?page=${pageNum}`,
        { headers: { token } }
      );

      let fetchedData = res.data.data || [];
      if (fetchedData.length === 0) {
        setHasMore(false);
      } else {
        setData(prevData => [...prevData, ...fetchedData]);
      }
    } catch (err) {
      console.error(err);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch initial data
  useEffect(() => {
    getData(page);
  }, [page]);

  // Infinite scroll logic
  useEffect(() => {
    const infiniteScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (!loading && hasMore) {
          setPage(prev => prev + 1);
        }
      }
    };

    window.addEventListener("scroll", infiniteScroll);
    return () => window.removeEventListener("scroll", infiniteScroll);
  }, [loading, hasMore]);

  // Memoize the card rendering function
  const renderCard = useMemo(() => (el, i) => (
    <Link to={`/collection/liked-collection/${el.slug}`} key={i}>
      <Box
        bg={bgColor}
        borderRadius="lg"
        border="1px"
        borderColor={borderColorValue}
        transition="all 0.2s"
        _hover={{ 
          borderColor: "blue.400",
          transform: "translateY(-2px)",
          boxShadow: cardShadow
        }}
      >
        <Box p={6}>
          <Box
            width="100%"
            bg={iconGridBg}
            borderRadius={5}
            p={4}
            mb={4}
          >
            <Flex 
              gap={3} 
              flexWrap="nowrap"
              justifyContent="space-between"
              width="100%"
              overflow="hidden"
            >
              {[0, 1, 2, 3, 4, 5].map((index) => (
                el?.tool[index]?.logoURL && (
                  <Box
                    key={index}
                    display={{ base: index > 3 ? 'none' : 'block', md: 'block' }}
                    flexShrink={0}
                  >
                    <Image
                      src={`${el.tool[index].logoURL}?height=30`}
                      borderRadius="4px"
                      width="40px"
                      height="40px"
                      objectFit="contain"
                      alt={`Tool ${index + 1} logo`}
                    />
                  </Box>
                )
              ))}
            </Flex>
          </Box>

          <Text
            fontSize="lg"
            fontWeight="600"
            color={heading}
            mb={2}
            noOfLines={1}
          >
            {el?.space_name}
          </Text>

          <Text
            color={font}
            fontSize="sm"
            mb={4}
            noOfLines={2}
          >
            {el?.description}
          </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            borderTop="1px"
            borderColor={borderColorValue}
          >
            <Flex alignItems="center" gap={2}>
              <Avatar
                src={el?.userID?.image}
                size="xs"
              />
              <Text fontSize="sm" color={font}>
                {el?.userID?.name?.length > 15 
                  ? `${el.userID.name.substring(0, 15)}...` 
                  : el.userID.name}
              </Text>
            </Flex>
            <Text
              fontSize="sm"
              color={toolsColor}
            >
              {el?.tool.length} tools
            </Text>
          </Flex>
        </Box>
      </Box>
    </Link>
  ), [bgColor, borderColorValue, font, heading, iconGridBg, cardShadow, toolsColor]);

  return (
    <>
      <Grid
        templateColumns={{ 
          base: "repeat(1, 1fr)", 
          md: "repeat(2, 1fr)", 
          lg: "repeat(3, 1fr)" 
        }}
        gap={6}
      >
        {data?.map(renderCard)}
      </Grid>

      {loading && (
        <Flex justifyContent="center" width="100%" py={4}>
          <LoadingAnimation size="md" />
        </Flex>
      )}

      {!hasMore && data.length > 0 && (
        <Text textAlign="center" color={font} py={4}>
          You've reached the end
        </Text>
      )}

      {!hasMore && data.length === 0 && (
        <Text textAlign="center" color={font} py={4}>
          No items found
        </Text>
      )}
    </>
  );
};
