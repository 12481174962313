import * as React from "react";
import chatgpt from "../Utils/ChatGPT.svg";
import { Spinner } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { MdBookmarkBorder, MdBookmark } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,Tooltip
} from "@chakra-ui/react";
import style from "../Style/List.module.css";
import { BsClockHistory } from "react-icons/bs";
import visit from "../Utils/Visit.svg";
import Modal from "@mui/material/Modal";
import { CloseIcon } from "@chakra-ui/icons";
import { Telement } from "./Tool/Telement";
import { Link, json } from "react-router-dom";
import ShareModel from "./Share";
import { Likes } from "./Likes";
import { MdOutlineVerified } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import Save_home from "./Home/Save_home";
import excel from "../Utils/Spreadsheet_icon.svg";
import sopify from "../Utils/sopify.svg";
import vscode from "../Utils/Vs code.svg";
import figma from "../Utils/Figma.svg";
import github from "../Utils/Github.svg";
import mobile from "../Utils/Mobile app.svg";
import web from "../Utils/Web.svg";
import slack from "../Utils/Slack.svg";
import browser from "../Utils/Browser Extension.svg";
import Wordpress from "../Utils/Wordpress.svg";
import { useState, useEffect, useRef } from "react";
import { AiFillDollarCircle, AiFillGift } from "react-icons/ai";
// import SaveButtonForCollections from "./Home/SaveButtonForCollections";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import {toolspagetitle} from "../Pages/Tools-page-title";


// export const ImageBackground = ({ el, imageUrl }) => {
//   const [oWidth, setOriginalWidth] = useState(null);
//   const [oHeight, setOriginalHeight] = useState(null);

  
//   const handleImageLoad = (event) => {
//     const imgElement = event.target;
//     setOriginalWidth(imgElement.naturalWidth);
//     setOriginalHeight(imgElement.naturalHeight);
//   };

 
//   return (
//     <>
//       {oHeight < 80 && oWidth < 80 ? (
//         <Box
//           display="flex"
//           className={style.iconImg2}
//           borderRadius="5px"
//           justifyContent="center"
//           alignItems="center"
//         >
//           <img
//             onLoad={handleImageLoad}
//             style={{ margin: "auto", display: "block", borderRadius: "5px" }}
//             className={style.iconImg}
//             src={imageUrl}
//             // alt={`${el.Title} logo`} 
//           />
//         </Box>
//       ) : (
//         <Box
//           display="flex"
//           className={style.iconImg2}
//           borderRadius="5px"
//           justifyContent="center"
//           alignItems="center"
//         >
//           <img
//             onLoad={handleImageLoad}
//             style={{ margin: "auto", display: "block", borderRadius: "5px" }}
//             width="100%"
//             src={imageUrl}
           
//           />
//         </Box>
//       )}
//     </>
//   );
// };


const updateDocumentTitleAndMeta = (data) => {
  const title = toolspagetitle(data);
  document.title = title;
};



export const ListModal_home = ({ el, i, setcat, setFilterLoader, setPageName }) => {
  const [open, setOpen] = React.useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const prevUrl = useRef(window.location.pathname);
  const [loading, setLoading] = React.useState(false); // New state for loading spinner
  const [initialState, setInitialState] = useState({ url: '', title: '' });

  useEffect(() => {
    setInitialState({ 
      url: window.location.pathname + window.location.search, // Include query parameters
      title: document.title 
    });
  }, []);

  const bgColor = useColorModeValue("#F5F9FD", "#303030"); // Example colors
  const boxShadow = useColorModeValue("0px 4px 10px rgba(0, 0, 0, 0.1)", "0px 4px 10px rgba(255, 255, 255, 0.1)");

  const heading = useColorModeValue("grey.800", "#eeeeee");
  const font = useColorModeValue("grey.700", "grey.400");
  const boxShadowColor = useColorModeValue('0px 4px 10px rgba(0, 0, 0, 0.2)', '0px 4px 10px rgba(255, 255, 255, 0.2)');

  useEffect(() => {
    const currentPath = location.pathname;
    const shouldOpenModal = currentPath.includes(`/tool/${el?.slug}`);
    if (shouldOpenModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location, el?.slug]);

  const handleOpen = () => {
    setLoading(true);
    // Update prevUrl to include query parameters
    prevUrl.current = window.location.pathname + window.location.search;
    updateDocumentTitleAndMeta(el);
    window.history.pushState({}, '', `/tool/${el?.slug}`);


    // Simulate a delay or wait for an async operation
    setTimeout(() => {
      setOpen(true);
      setLoading(false); // Deactivate spinner once the modal is ready
    },); // Adjust this timeout as needed
  };


  const handleClose = () => {
    setOpen(false);
    document.title = initialState.title;
    // Use initialState.url which includes query parameters
    window.history.pushState({}, '', initialState.url);
  };  
  

  // Detect browser back button press to close the modal
  useEffect(() => {
    const handlePopState = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [open]);

  let url = `${window.location.origin}/tool/${el?.slug}`;
  // let url = `${process.env.REACT_APP_API}/tool/${el?._id}`;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // const handleSaveClick = () => {
  //   // This function would mimic whatever happens when <Save el={el} /> is clicked.
  //   // If <Save el={el} /> opens a modal, you should trigger the same action here.
  // };

  
  return (
    <>

{loading && (
        <Box position="fixed" top="50%" left="50%"  transform="translate(-50%, -50%)">
          <Spinner thickness="3px"
        speed="0.90s"
        emptyColor="gray.400"
        color="blue.500"
        size={"xl"} />
        </Box>
      )}
    
    <Stack w={{ base: "100%", md: "332px" }}  mb="20px" >
    <Flex
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    position="relative"
    width={"100%"}
    border={"1px"}
    h={{ base: "148px", md: "185px" }}
    // h= "190px"
    // minHeight="185px"
    borderColor={colorMode === "light" ? "#e0e0e0e6" : "#333333"}
    borderRadius={"10px"}
    paddingX={{ base: "12px", md: "15px" }}
    paddingY={{ base: "10px", md: "15px" }}
    bg={useColorModeValue("#ffffff", "#222222")}
    boxShadow={"0 2px 4px rgba(0,0,0,0.1)"} 
    // justifyContent="space-between"
    // alignItems={"flex-start"}
    transition="transform 0.3s, box-shadow 0.3s" 
      _hover={{ transform: "translateY(-5px)", boxShadow: boxShadowColor  }}
    flexDirection="column" // Adjust the direction to column to stack children vertically
    
  >
        <Flex alignItems={"flex-start"} gap={{ base: 3, md: 5 }}   px={1}  
        
         >
        <a 
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
      >
          <Flex 
          cursor={"pointer"} 
          onClick={handleOpen}
          py={5} 
          mt={-4}
         
          >
           
        <Box
          display="flex"
          className={style.iconImg2}
          borderRadius="5px"
          justifyContent="center"
          alignItems="center"
          
        >
          <img   
           loading="lazy"    
            style={{ margin: "auto", display: "block", borderRadius: "5px" }}
            className={style.iconImg}
            width="80px"
            height="80px"
            objectFit="contain"
            src={el?.Logo
                ? `${el?.Logo}?height=80`
                : "https://ai-zones.b-cdn.net/Website-utilities%20/favicon-500px.png?height=80"
            }
            alt={`${el.Title} logo`} 
          />
 
        </Box>
     
          </Flex>
          </a>

          <Flex flexDirection={"column"} gap={"5px"} >
          <a 
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
         >
            <Flex alignItems="center" justifyContent={"flex-start"}>
            <Text

    fontSize="15px"
    // lineHeight="24px"
    fontWeight="600"
    className={style.title}
    cursor={"pointer"}
    onClick={handleOpen}
    color={heading}
    as="h1"
  >
    {el?.Title}
  </Text>

  {/* Conditional 18+ Badge if category contains NSFW */}
  {el?.Category?.includes("NSFW") && (
    <Box
      ml={2}
      px={1.5}
      py={0.5}
      bg="red.500"
      color="white"
      fontSize="10px"
      fontWeight="bold"
      borderRadius="4px"
      lineHeight="normal"
    >
      18+
    </Box>
  )}
              { el?.featured && (
                <Text
                //   ml="9px"
                  // textAlign="start"
                  // justifyContent="start"
                  w="fit-content"
                  px="5px"
                //   py="1px"
                  bg="linear-gradient(134deg, #4283B0 8.39%, #F31F92 70.00%)"
                  borderRadius="3px"
                  fontSize="12px"
                  color="#EEE"
                  lineHight="23px"
                  fontWeight="400"
                >
                  Featured
                </Text>
              )}
            </Flex>
            </a>

            <Flex flexDirection="column" gap="5px">
  <Flex alignItems="center" gap={2}>
    {el?.rating > 0 && (
      <Flex alignItems="center" gap={1}>
        <FaStar size={10} color="#ECBA67" />
        <Text fontSize="13px">{el?.rating}</Text>
      </Flex>
    )}

    {/* Pricing Section */}
    <Box
      paddingX="8px"
      fontSize="12px"
      fontWeight="400"
      lineHeight="16px"
      borderLeft="1px"
      borderRight="1px"
      borderColor={useColorModeValue("#e0e0e0", "#444")}
      display="flex"
      alignItems="center"
      gap="7px"
    >
      {/* Render Icon and Pricing Text Conditionally */}
      {el?.Pricing && (
        <>
          {/* {el.Pricing === "Free" && <AiFillGift size={10} />}
          {el.Pricing === "Free trial" && <BsClockHistory size={11} />}
          {el.Pricing === "Freemium" && <MdOutlineVerified size={11} />}
          {el.Pricing === "Paid" && <AiFillDollarCircle size={11} />}
          <Text fontSize="12px">{el.Pricing}</Text> */}

          {el.Pricing === "Free" && <AiFillGift size={13}  />} {/* Bright Green - represents free/no cost */}
          {el.Pricing === "Free trial" && <BsClockHistory size={12} />} {/* Orange - temporary/limited time */}
          {el.Pricing === "Freemium" && <MdOutlineVerified size={13}  />} {/* Blue - standard/basic */}
          {el.Pricing === "Paid" && <AiFillDollarCircle size={13} />} {/* Pink-Red - premium/paid */}
          <Text fontSize="12px">{el.Pricing}</Text>
        </>
      )}
    </Box>


                {el?.price_amount && (
                  <Box
                    paddingRight="8px"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    borderRight="1px"
                    borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"}
                  >
                    {el?.price_amount}
                  </Box>
                )}

                <Flex  alignItems={"center"}>
                    
                  {el?.works_with && el?.works_with.map((e, i) => (
                    <Box key={i} 
                    // padding="1px"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    // borderRight="1px"
                    borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"} >
                    
                      {e.includes("Spreadsheet") ? (
                        <img
                          alt="Spreadsheet logo"
                          borderRadius="4px"
                          boxSize="12px"
                          src={excel}
                        />
                      ) : e.includes("Chatgpt(Plugin)") ? (
                        <img
                          alt="Chatgpt(Plugin) logo"
                          boxSize="12px"
                          width={"80%"}
                          src={chatgpt}
                        />
                        ) : e.includes("Web") ? (
                          <img
                            alt="Web logo"
                            boxSize="12px"                           
                            width={"13px"}
                            src={web}
                          />
                      ) : e.includes("VS Code") ? (
                        <img
                          alt="VS Code logo"
                          boxSize="12px"
                          width={"80%"}
                          src={vscode}
                        />
                      ) : e.includes("Github") ? (
                        <img
                          alt="Github logo"
                          boxSize="12px"
                          width={"80%"}
                          src={github}
                        />
                      ) : e.includes("Mobile app") ? (
                        <img
                          alt="Mobile app logo"
                          boxSize="12px"
                          width={"80%"}
                          src={mobile}
                        />
                      ) : e.includes("Wordpress") ? (
                        <img
                          alt="Wordpress logo"
                          boxSize="12px"
                          width={"80%"}
                          src={Wordpress}
                        />
                      ) : e.includes("Figma") ? (
                        <img
                          alt="Figma logo"
                          boxSize="12px"
                          width={"80%"}
                          src={figma}
                        />
                      ) : e.includes("Browser Extension") ? (
                        <img
                          alt="Browser Extension logo"
                          boxSize="12px"
                          width={"80%"}
                          src={browser}
                        />
                      ) : e.includes("Slack") ? (
                        <img
                          alt="Slack logo"
                          boxSize="12px"
                          width={"80%"}
                          src={slack}
                        />
                      ) : e.includes("Shopify") ? (
                        <img
                          alt="Shopify logo"
                          boxSize="12px"
                          width={"80%"}
                          src={sopify}
                        />
                      ) : (
                        ""
                      )}
                    </Box>                    
                  ))}

</Flex>
</Flex>
          

{el?.Category && el?.Category.length > 0 && (
  <Flex alignItems={"center"}>
    {/* Category 1 */}
    <Text
      px="1"
      fontSize="12px"
      fontWeight="400"
      lineHeight="30px"
      color={colorMode === "light" ? "blue.500" : "blue.400"} 
      cursor="pointer"
      onClick={() => {
        setcat(el?.Category[0]);
        setPageName("filter");
        setFilterLoader((prev) => !prev);
      }}
    >
      #{el?.Category[0]}
    </Text>

    {/* Additional Categories Indicator */}
    {el?.Category.length > 1 && (
      <Tooltip label={el?.Category.slice(1).join(", ")} fontSize="xs">
        <Text 
          ml="1"
          fontSize="12px"
          fontWeight="400"
          lineHeight="20px"
          color={colorMode === "light" ? "blue.500" : "blue.400"} 
          cursor="pointer"
          onClick={() => {
            setcat(el?.Category[1]);
            setPageName("filter");
            setFilterLoader((prev) => !prev);
          }}
        >
          +{el?.Category.length - 1}
        </Text>
      </Tooltip>
    )}
  </Flex>
)}
                </Flex>
                </Flex>
                </Flex>

             

            {/* </Flex>             */}
         
        

            <Box
            display={{ base: "flex", md: "flex", lg: isHovered ? "flex" : "flex" }}
    position="absolute"
    top="0.1px" // Adjust the value as needed
    right="0.1px" // Adjust the value as needed
    // display="flex"
    flexDirection="column"
    alignItems="flex-end"
  > 

<a 
  href={`${el?.URL}${el?.URL.includes('?') ? '&' : '?'}utm_source=aizones&utm_medium=referral`} 
  target="_blank" 
>
     <Box position="absolute" padding={1} top={0.5} right={2}>
       <ExternalLinkIcon color="blue.300" boxSize={4} aria-label="View Product Page"/>
     </Box>
   </a>
      </Box>
 
  {/* <Box position="absolute" bottom="5px" right="1px">    
            <Save_home el={el} />
          </Box> */}

      <Flex position="absolute" bottom="5px" right="2px" alignItems="center" gap="5px"
      display={{ base: "flex", md: "flex", xl: isHovered ? "flex" : "none" }}

      

      >
        <Flex px={2}>
         <ShareModel url={url} />
         </Flex>
            {/* Likes Section (with like count beside the icon) */}
            <Flex fontSize="10px" alignItems="center" border="1px" px={1} borderRadius={5} borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"} >
              {/* Resize icon in Likes component if needed */}
              <Text mr="2" fontSize="10px" lineHeight="25px" fontWeight="600">
                {el?.likes}
              </Text>
              <Likes el={el} /> 
            </Flex>

            {/* Save_home Section */}
            <Save_home el={el} />
             
          </Flex>
        {/* <Box display={{ base: "flex", md: "flex" }}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BiDotsVerticalRounded size={25} />}
              variant="outline"
              border={"none"}
              size={0}
              _hover={{ backgroundColor: "" }}
              _active={{ backgroundColor: "" }}
            />
            <MenuList
              padding={3}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
            >
              <Flex
                alignItems="center"
                gap="5px"
                fontSize="12px"
                fontWeight="400"
                lineHeight="20px"
              >
                <Text
                  textAlign="center"
                  border="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                  px="10px"
                  borderRadius="12px"
                  cursor="pointer"
                  onClick={() => {
                    // setcat((pre) => [...pre, el?.Category[0]]);
                    setcat(el?.Category[0]);

                    setPageName("filter");
                    setFilterLoader((prev) => !prev);
                  }}
                >
                  {el?.Category[0]}
                </Text>
                <Text
                  display={el?.Category.length > 1 ? "block" : "none"}
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="20px"
                  border="1px"
                  borderColor={useColorModeValue("#e0e0e0", "#444")}
                  px="10px"
                  borderRadius="12px"
                  cursor="pointer"
                >
                  + {el?.Category.length - 1}
                </Text>
              </Flex>
              <MenuItem _hover={{ backgroundColor: "inherit" }} py={0} px={0}>
                <a
  href={`${el?.URL}${el?.URL.includes('?') ? '&' : '?'}utm_source=aizones&utm_medium=referral`}
  target="_blank"
  style={{ width: "100%" }}
>
                  <Button
                    width={"100%"}
                    h="29.68px"
                    color="white"
                    borderRadius="5px"
                    fontSize="14px"
                    fontWeight="400"
                    gap="5px"
                    bg="#3B89B6"
                    _hover={{ bg: "" }}
                  >
                     <img src={visit} alt="visit" /> 
                    <Text>Visit Site</Text>
                  </Button>
                </Link>
              </MenuItem>
              <MenuItem  _hover={{ backgroundColor: "inherit" }} py={0} px={0}>
                <Flex
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  
                >
                  <Box
                   paddingX={3}
                    paddingY={"9px"}
                    border="1px"
                    borderColor={useColorModeValue("#e0e0e0", "#444")}
                    borderRadius={"5px"}
                  >
                    <Likes el={el} />
                  </Box >
                  <Save el={el} py={40} />
                  <Box
                    padding={3}
                    border="1px"
                    borderColor={useColorModeValue("#e0e0e0", "#444")}
                    borderRadius={"5px"}
                  >
                    <ShareModel url={url} />
                  </Box>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
          
        </Box> */}
        <Box display={{ base: "block", md: "none" }} cursor={"pointer"} onClick={handleOpen}>
    <Text as="h2" fontSize={"15px"} color={useColorModeValue("gray.600", "gray.400")}>
      {`${el?.Taline}`.length > 60
        ? `${el?.Tagline}`.substring(0, 60) + "..."
        : `${el?.Tagline}`}
    </Text>
  </Box>

  {/* Tagline for medium screens */}
  <Box display={{ base: "none", md: "block", lg: "none" }} cursor={"pointer"} onClick={handleOpen}>
    <Text as="h2"fontSize={"15px"} color={useColorModeValue("gray.600", "gray.400")}>
      {`${el?.Tagline}`.length > 70
        ? `${el?.Tagline}`.substring(0, 70) + "..."
        : `${el?.Tagline}`}
    </Text>
  </Box>

  {/* Tagline for large screens */}
  <Box display={{ base: "none", lg: "block" }} cursor={"pointer"} onClick={handleOpen}>
    <Text as="h2" fontSize={"15px"} color={useColorModeValue("gray.600", "gray.400")}>
      {`${el?.Tagline}`.length > 90
        ? `${el?.Tagline}`.substring(0, 90) + "..."
        : `${el?.Tagline}`}
    </Text>
  </Box>
        
      </Flex>

      <Modal  loading="lazy" className={style.modal} open={open} onClose={handleClose}>
        <Box
          // bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}
          bgGradient={useColorModeValue(
            "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
            "linear(to right, #222222, #303030)" // dark mode grey gradient
          )}
          overflow="auto"
          sx={{ width: "fit-content" }}
          borderRadius={10}
          mt={70}
        >
          <Flex
            justifyContent="space-between"
            w="90%"
            alignItems="center"
            margin="auto"
            mt="20px"
            mb={"20px"}
          >
            <Box></Box>
            <Box
            as={CloseIcon}
            cursor="pointer"
            onClick={handleClose}
            pos="fixed"
            bg="#666" // A professional shade of gray
            w="1.5em"
            h="1.5em"
            borderRadius="50%" // Makes it a circle
            border="2px solid #666" // A darker border for contrast
            padding="2px"
            zIndex="55"
            color= "white"
            boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)" // Subtle shadow for depth               
            transition="all 0.3s ease-in-out" // Smooth transition
           />
             <a href={`/tool/${el?.slug}`} target="_blank" >
          <Text
            cursor={"pointer"}
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            padding="3px"
            px={2}
            borderRadius="5px"
    >
        Open in new tab
          </Text>
             </a>
          </Flex>
                  
          <Telement el={el} id={el?._id}  handleClose={handleClose} />
        </Box>
      </Modal>
     
    </Stack>
    </>
  );
};

