import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
  Grid, GridItem, Tooltip
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { IoIosArrowForward } from "react-icons/io";
import style from "../Style/Grid.module.css";
import visit from "../Utils/Visit.svg";
// import nologo from "../Utils/NO COVER_logo.png";
import Modal from "@mui/material/Modal";
import { CloseIcon } from "@chakra-ui/icons";
import { Telement } from "./Tool/Telement";
import { Link } from "react-router-dom";
import ShareModel from "./Share";
import { MdOutlineVerified } from "react-icons/md";
import { Likes } from "./Likes";
import Save_home from "./Home/Save_home";
import { FaStar } from "react-icons/fa";
import { LandingRight } from "./LandingRight";
import axios from "axios";
import excel from "../Utils/Spreadsheet_icon.svg";
import chatgpt from "../Utils/ChatGPT.svg";
import vscode from "../Utils/Vs code.svg";
import figma from "../Utils/Figma.svg";
import github from "../Utils/Github.svg";
import mobile from "../Utils/Mobile app.svg";
import slack from "../Utils/Slack.svg";
import browser from "../Utils/Browser Extension.svg";
import Wordpress from "../Utils/Wordpress.svg";
import web from "../Utils/Web.svg";
import sopify from "../Utils/sopify.svg";
import { AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import {toolspagetitle} from "../Pages/Tools-page-title";



const updateDocumentTitleAndMeta = (data) => {
  const title = toolspagetitle(data);
  document.title = title;

  
};

export const Modalcomp = ({ el, i, Title, setcat, setPageName, setFilterLoader }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { colorMode, toggleColorMode } = useColorMode();  

  const navigate = useNavigate();
  const location = useLocation();
  const prevUrl = useRef(window.location.pathname);
  const [loading, setLoading] = React.useState(false); // New state for loading spinner
  const [initialState, setInitialState] = useState({ url: '', title: '' });
  


  useEffect(() => {
    setInitialState({ 
      url: window.location.pathname, 
      title: document.title 
    });
  }, []);


  let { id } = useParams();
  let idSplit = id && id.split("-");

  const handleOpen = () => {
    setLoading(true);
    prevUrl.current = window.location.pathname;
    updateDocumentTitleAndMeta(el);
  window.history.pushState({}, '', `/tool/${el?.slug}`);


    // Simulate a delay or wait for an async operation
    setTimeout(() => {
      setOpen(true);
      setLoading(false); // Deactivate spinner once the modal is ready
    },); // Adjust this timeout as needed
  };


  const handleClose = () => {
    setOpen(false);
    document.title = initialState.title;
    window.history.pushState({}, '', initialState.url);
  };  
  let url = `${window.location.origin}/tool/${el?.slug}`;
  
  const boxShadowColor = useColorModeValue('0px 4px 10px rgba(0, 0, 0, 0.2)', '0px 4px 10px rgba(255, 255, 255, 0.2)');


    
  return (
    <>
    {loading && (
        <Box position="fixed" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner thickness="3px"
        speed="0.90s"
        emptyColor="gray.200"
        color="blue.500"
        size={"xl"} />
        </Box>
      )}
      <Box
        position="relative"
        border="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
        borderRadius="5px"
        textAlign="left"
        mb={2}
        h={350}
        bg={useColorModeValue("#ffffff", "#222222")}
        boxShadow={"0 2px 4px rgba(0,0,0,0.1)"} 
        transition="transform 0.3s, box-shadow 0.3s" 
      _hover={{ transform: "translateY(-5px)", boxShadow: boxShadowColor  }}  
      >
        <Box position="relative">
        <a 
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
         >
          <Box className={style.coveImage} >
            {/* ////////////////////////////////////////// */}
            <Image
             
              boxSize="100%"
              objectFit="scale-down"
              h="100%"
              w="100%"
              borderTopRadius="5px"
              style={{ cursor: "pointer" }}
              onClick={handleOpen}
              alt={`${el.Title} Cover image`} 
              src={
                el.Cover_image
                  ? `${el.Cover_image}?aspect_ratio=16:9&width=473&height=180`
                  : "https://ai-zones.b-cdn.net/Website-utilities%20/NO%20COVER%20.png?aspect_ratio=16:9&width=473&height=180"
              }
            />
          </Box>
          </a>

          {el.featured && (
            <Box position="absolute" top="0px" left="0">
              <Text
                m="9px"
                textAlign="center"
                justifyContent="center"
                w="fit-content"
                px="5px"
                py="1px"
                bg="linear-gradient(134deg, #4283B0 8.39%, #F31F92 70.00%)"
                borderRadius="3px"
                fontSize="12px"
                color="#EEE"
                lineHight="23px"
                fontWeight="400"
              >
                Featured
              </Text>
            </Box>
          )}

          <Flex
            position="absolute"
            top="8px"
            right="8px"
            gap="5px"
            w="fit-content"
          >
            {el?.works_with && el?.works_with.map((e, i) => (
              <Box key={i}>
                {e.includes("Spreadsheet") ? (
                  <Image
                    w="100%"
                    borderRadius="4px"
                    boxSize="18px"
                    src={excel}
                    alt="Spreadsheet logo"
                  />
                ) : e.includes("Chatgpt") ? (
                  <Image alt="Chatgpt(Plugin) logo" boxSize="18px" src={chatgpt} />
                ) : e.includes("VS Code") ? (
                  <Image alt="VS Code logo" boxSize="18px" src={vscode} />
                ) : e.includes("Github") ? (
                  <Image alt="Github logo" boxSize="18px" src={github} />
                ) : e.includes("Mobile app") ? (
                  <Image alt="Mobile app logo" boxSize="18px" src={mobile} />
                ) : e.includes("Wordpress") ? (
                  <Image alt="Wordpress logo" boxSize="18px" src={Wordpress} />
                ) : e.includes("Figma") ? (
                  <Image alt="Figma logo" boxSize="18px" src={figma} />
                ) : e.includes("Browser Extension") ? (
                  <Image alt="Browser Extension logo" boxSize="18px" src={browser} />
                ) : e.includes("Shopify") ? (
                  <Image alt="Shopify logo" boxSize="18px" src={sopify} />
               ) : e.includes("Web") ? (
                    <Image alt="Web logo" boxSize="18px" src={web} />
                ) : e.includes("Slack") ? (
                  <Image alt="Slack logo" boxSize="18px" src={slack} />
                ) : (
                  ""
                )}
              </Box>
            ))}
          </Flex>

          {el?.price_amount && (
            <Box
              position={"absolute"}
              bottom="10px"
              left="13px"
              bg={colorMode === "light" ? "White" : "#222222"}
              p="5px 7px"
              borderRadius="5px"
            >
              <Text fontSize={"12px"}>{el?.price_amount && el?.price_amount.split(",")[0]}</Text>
            </Box>
          )}

<Box
  position={"absolute"}
  bottom="10px"
  right="13px"
  bgGradient={useColorModeValue(
    "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
    "linear(to right, #222222, #303030)" // dark mode grey gradient
  )}
  px="10px"
 
  borderRadius="5px"
  color="black"
>
  <Box display="flex" alignItems="center">
    
    <ShareModel  url={url} />
    <Flex px={2}>
    <Save_home  id={el._id} el={el} />
    </Flex>
    <a
  href={`${el?.URL}${el?.URL.includes('?') ? '&' : '?'}utm_source=aizones&utm_medium=referral`}
  target="_blank" 
>
      <Box position="relative"  mb={1}>
        <ExternalLinkIcon color="blue.300" boxSize={4} aria-label="View Product Page"/>
      </Box>
    </a>
  </Box>
</Box>
        </Box>

        <Box m="10px">
          <Flex mt="8px" justifyContent="space-between" alignItems="start">
             <Flex
              cursor={"pointer"}
              maxW="60%"
              onClick={handleOpen}
              alignItems="center"
              gap="7px"
            >
              {el.Logo ? (
                <Image h="100%" alt={`${el.Title} Logo`} w="20px" src={`${el?.Logo}?height=20`} />
              ) : (
                <Image h="100%" w="20px" alt={`Default Tool's logo`} src={`${"https://ai-zones.b-cdn.net/Website-utilities%20/NO%20COVER_logo.png"}?height=20`} />
              )}

            <a
        href={`/tool/${el?.slug}`} 
        target="_blank" 
        
        onClick={(e) => {
          e.preventDefault(); // Prevents default navigation on left-click
          handleOpen(); // Calls your modal opening function
        }}
            >
<Flex alignItems="center" wrap="nowrap" gap="6px">
  {/* Title Container */}
  <Box 
    flex="1" /* Allows the title to take available space */
    minWidth="0" /* Ensures truncation works correctly */
  >
    <Text
      className={style.title}
      fontWeight="700"
      fontSize="16px"
      lineHeight="1.2"
      as="h1"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {el?.Title.split(" ").slice(0, 4).join(" ")} {/* Show first 4 words */}
      {el?.Title.split(" ").length > 4 ? "..." : ""}
    </Text>
  </Box>

  {/* Conditional 18+ Badge */}
  {el?.Category?.includes("NSFW") && (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      flexShrink="0" /* Prevents badge from shrinking */
      px="6px"
      py="2px"
      bg="red.500"
      color="white"
      fontSize="10px"
      fontWeight="bold"
      borderRadius="4px"
      lineHeight="1"
      whiteSpace="nowrap"
    >
      18+
    </Box>
  )}
</Flex>


             
              </a>
            </Flex>
            
            <Box
              fontSize="15px"
              fontWeight="400"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Likes el={el} />
              <Text fontSize="12px" fontWeight="600" lineHeight="25px">
                {el.likes}
              </Text>
            </Box>
          </Flex>

          <Flex alignItems={"center"} gap={2}>
                {el?.rating > 0 && (              
                <Flex alignItems={"center"} gap={1}>
                  <FaStar size={10} color={"#ECBA67"} />
                  <Text fontSize={"13px"}>{el?.rating}</Text>
                </Flex>
                 )} 

            <Box
              paddingRight="8px"
              paddingLeft="8px"
              fontSize="12px"
              fontWeight="400"
              lineHeight="16px"
              borderLeft="1px"
              borderRight={el?.works_with && el?.works_with.length ? "1px" : "0px"}
              borderColor={useColorModeValue("#e0e0e0", "#444")}
            >
              {el?.Pricing && el?.Pricing === "Free" ? (
                <Flex gap="7px" alignItems="center">
                  <AiFillGift size={10} />
                  <Text fontSize={"12px"}>{el?.Pricing}</Text>
                </Flex>
              ) : el?.Pricing === "Free trial" ? (
                <Flex gap="7px" alignItems="center">
                  <BsClockHistory size={11} />
                  <Text fontSize={"12px"}>{el?.Pricing}</Text>
                </Flex>
              ) : el?.Pricing === "Freemium" ? (
                <Flex gap="7px" alignItems="center">
                  <MdOutlineVerified size={11} />
                  <Text fontSize={"12px"}>{el?.Pricing}</Text>
                </Flex>
              ) : el?.Pricing === "Paid" ? (
                <Flex gap="7px" alignItems="center">
                  <AiFillDollarCircle size={11} />
                  <Text fontSize={"12px"}>{el?.Pricing}</Text>
                </Flex>
              ) : (
                ""
              )}
            </Box>

            {/* {el?.price_amount && (
              <Box
                paddingRight="8px"
                fontSize="12px"
                fontWeight="400"
                lineHeight="16px"
                borderRight="1px"
                borderColor={colorMode === "light" ? "#e0e0e0" : "#333333"}
              >
                {el?.price_amount}
              </Box>
            )} */}

            <Flex gap="7px" alignItems={"center"}>
              {el?.works_with && el?.works_with.map((e, i) => (
                <Box key={i}>
                  {e.includes("Spreadsheet") ? (
                    <img
                      alt="icon"
                      borderRadius="4px"
                      boxSize="12px"
                      src={excel}
                    />
                  ) : e.includes("Chatgpt(Plugin)") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={chatgpt}
                    />
                    ) : e.includes("Web") ? (
                      <img
                        alt="icon"
                        boxSize="12px"                           
                        width={"13px"}
                        src={web}
                      />
                  ) : e.includes("VS Code") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={vscode} />
                  ) : e.includes("Github") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={github} />
                  ) : e.includes("Mobile app") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={mobile} />
                  ) : e.includes("Wordpress") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={Wordpress}
                    />
                  ) : e.includes("Figma") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={figma} />
                  ) : e.includes("Browser Extension") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={browser}
                    />
                  ) : e.includes("Slack") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={slack} />
                  ) : e.includes("Shopify") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={sopify} />
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Flex>
          </Flex>

          <Text
            className={style.dec}
            mt="15px"
            fontSize="15px"
            lineHeight="24px"
            fontWeight="400"
            as="h2"
            cursor={"pointer"} onClick={handleOpen}
          >
            {el.Tagline}
          </Text>
          <Flex
            alignItems="center"
            mt="10px"
            gap="5px"
            fontSize="12px"
            fontWeight="400"
            lineHeight="20px"
          >
             <Text
      px="1"
      fontSize="12px"
      fontWeight="400"
      lineHeight="20px"
      color={colorMode === "light" ? "blue.500" : "blue.400"} 
      cursor="pointer"
      onClick={() => {
        setcat(el?.Category[0]);
        setPageName("filter");
        setFilterLoader((prev) => !prev);
      }}
    >
      #{el?.Category[0]}
    </Text>
    {el?.Category.length > 1 && (
      <Tooltip label={el?.Category.slice(1).join(", ")} placement="top" fontSize="xs">
        <Text 
          ml="1"
          fontSize="12px"
          fontWeight="400"
          lineHeight="20px"
          color={colorMode === "light" ? "blue.500" : "blue.400"} 
          cursor="pointer"
          onClick={() => {
            setcat(el?.Category[1]);
            setPageName("filter");
            setFilterLoader((prev) => !prev);
          }}
        >
          +{el?.Category.length - 1}
        </Text>
      </Tooltip>
    )}
          
          </Flex>

          <Flex
            mt="10px"
            justifyContent="right"
            alignItems="right"
            mb="10px"
            w="98%"
            gap="10px"
          >
           
          </Flex>
        </Box>
      </Box>
          
      <Modal className={style.modal} open={open} onClose={handleClose}>
        <Box
          // bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}
          bgGradient={useColorModeValue(
            "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
            "linear(to right, #222222, #303030)" // dark mode grey gradient
          )}
          overflow="auto"
          sx={{ width: "fit-content" }}
          borderRadius={10}
          mt={70}
        >
          <Flex
            justifyContent="space-between"
            w="90%"
            alignItems="center"
            margin="auto"
            mt="20px"
            mb={"20px"}
          >
            <Box></Box>
            <Box
            as={CloseIcon}
            cursor="pointer"
            onClick={handleClose}
            pos="fixed"
            bg="#666" // A professional shade of gray
            w="1.5em"
            h="1.5em"
            borderRadius="50%" // Makes it a circle
            border="2px solid #666" // A darker border for contrast
            padding="2px"
            zIndex="55"
            color= "white"
            boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)" // Subtle shadow for depth               
            transition="all 0.3s ease-in-out" // Smooth transition
           />
             <a href={`/tool/${el?.slug}`} target="_blank" >
          <Text
            cursor={"pointer"}
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            padding="3px"
            px={2}
            borderRadius="5px"
    >
        Open in new tab
          </Text>
             </a>
          </Flex>
                  
          <Telement el={el} id={el?._id} handleClose={handleClose} />
        </Box>
      </Modal>
     
    </>
  );
};
