import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homepageDataLoading } from "../Redux/action";
import { AiOutlineHeart } from "react-icons/ai";
import { Box } from "@chakra-ui/react";
// import LoginModel from "./model/LoginModel";
import { useNavigate } from 'react-router-dom';
import HandleGoogle from '../Firebase/handleGoogleAuth';



export const Likes = ({ el }) => {
  
  const [show, setShow] = useState(false);
  let [isLikes, setIsLikes] = React.useState(false);

  // const handleOpen = () =>{
  //   setShow(true)
  // }
  // const handleClose = () =>{
  //   setShow(false)
  // }

  const userData = useSelector((state) => state.userReducer.loginData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLike = async () => {
    let token = userData.data;
    if (!token) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, () => postLike());
      return;
    }
    postLike();
  };
  
  const postLike = async () => {
    // Optimistically update the UI for likes count and color
    const wasLiked = isLikes;
    const newLikesCount = wasLiked ? el.likes - 1 : el.likes + 1;
    setIsLikes(!wasLiked);
    el.likes = newLikesCount;
  
    try {
      const id = el._id;
      await axios.patch(
        `${process.env.REACT_APP_API}/data/update/${id}/add-like`,
        null,
        { headers: { token: userData.data } }
      );
      dispatch(homepageDataLoading());
    } catch (err) {
      // Revert UI changes if there's an error
      el.likes = wasLiked ? newLikesCount + 1 : newLikesCount - 1;
      setIsLikes(wasLiked);
      //console.log(err);
    }
  };

  return (
    <Box>
      <AiOutlineHeart

        size={16}
        cursor="pointer"
        onClick={handleLike}
        color={isLikes ? "tomato" : "#3B89B6"}
      />
      {/* {show ? <LoginModel onCloseOuter={handleClose}/> : null} */}
    </Box>
  );
};
