// import React, { useEffect } from "react";
// import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// import axios from "axios";

// const AdBanner = ({ adTool }) => {
//   const bg = useColorModeValue("#ffffff", "#222222");
//   const font = useColorModeValue("grey.700", "grey.400");
//   const heading = useColorModeValue("grey.800", "#eeeeee");

//   // Handle Sponsored Tool Click
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   // Handle Sponsored Tool Impression
//   const handleSponsoredToolImpression = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
//     } catch (error) {
//       console.error("Error incrementing impression count:", error);
//     }
//   };

//   // Trigger impression when the ad is loaded
//   useEffect(() => {
//     if (adTool?._id) {
//       handleSponsoredToolImpression(adTool._id);
//     }
//   }, [adTool]);

//   // If no ad data is provided, render nothing
//   if (!adTool) return null;

//   return (
//     <Box
//       as="a"
//       href={adTool.URL || "#"}
//       target="_blank"
//       
//       position="relative"
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//       border="1px solid"
//       borderColor={font}
//       borderRadius="8px"
//       padding="16px"
//       bg={bg}
//       marginBottom="20px"
//       boxShadow="md"
//       onClick={() => handleSponsoredToolClick(adTool._id)}
//       style={{
//         gridColumn: "1 / -1", // Spans full width of grid
//         background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
//       }}
//       _hover={{ boxShadow: "lg" }}
//       _focus={{ boxShadow: "lg" }}
//     >
//       {/* Gradient Highlight */}
//       <Box
//         position="absolute"
//         top="0"
//         left="0"
//         bottom="0"
//         width="4px"
//         bg="linear-gradient(to bottom, lightblue, pink)"
//         borderRadius="8px 0 0 8px"
//       />

//       {/* Sponsored Label */}
//       <Box
//         position="absolute"
//         top="8px"
//         right="8px"
//         background="rgba(0, 0, 0, 0.7)"
//         color="#ffffff"
//         fontSize="12px"
//         fontWeight="bold"
//         borderRadius="4px"
//         padding="4px 6px"
//       >
//         Sponsored
//       </Box>

//       {/* Ad Content */}
//       <Flex alignItems="center" gap={4} >
//         {/* Ad Logo */}
//         <img
//           src={adTool.Logo}
//           alt={`${adTool.Title} logo`}
//           style={{
//             height: "80px",
//             width: "80px",
//             borderRadius: "5px",
//             objectFit: "cover",
//           }}
//         />

//         {/* Title and Tagline */}
//         <Flex flexDirection="column" color={heading} flex="1">
//           <Text fontSize="lg" fontWeight="bold">
//             {adTool.Title}
//           </Text>
//           <Text fontSize="sm" color={font}>
//             {adTool.Tagline}
//           </Text>
//         </Flex>
//       </Flex>
//     </Box>
//   );
// };

// export default AdBanner;






























// import React, { useEffect, useState } from "react";
// import { Box, Flex, Text, useColorModeValue, Image, useBreakpointValue } from "@chakra-ui/react";
// import axios from "axios";

// const AdBanner = () => {
//   const [adTool, setAdTool] = useState(null); // Store the ad tool
//   const bg = useColorModeValue("#ffffff", "#222222");
//   const font = useColorModeValue("grey.700", "grey.400");
//   const heading = useColorModeValue("grey.800", "#eeeeee");

//   // Fetch a random tool
//   const fetchRandomTool = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//       const tools = response.data;

//       if (tools.length > 0) {
//         const randomTool = tools[Math.floor(Math.random() * tools.length)]; // Get a random tool
//         setAdTool(randomTool);
//       }
//     } catch (error) {
//       console.error("Error fetching sponsored tools:", error);
//     }
//   };

//   // Handle Sponsored Tool Click
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   // Handle Sponsored Tool Impression
//   const handleSponsoredToolImpression = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
//     } catch (error) {
//       console.error("Error incrementing impression count:", error);
//     }
//   };
  
//   const isMobile = useBreakpointValue({ base: true, md: false });

//   // Fetch the ad tool once when the component mounts
//   useEffect(() => {
//     fetchRandomTool();
//   }, []); // Empty dependency array ensures this runs only once

//   // Trigger impression when the ad is loaded
//   useEffect(() => {
//     if (adTool?._id) {
//       handleSponsoredToolImpression(adTool._id);
//     }
//   }, [adTool?._id]); // Ensure this only runs when `adTool._id` changes

//   // If no ad data is available, render nothing
//   if (!adTool) return null;

  

//   return (
//     <Box
//       as="a"
//       href={adTool.URL || "#"}
//       target="_blank"
//       
//       position="relative"
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//       border="1px solid"
//       borderColor={font}
//       borderRadius="8px"
//       padding="16px"
      
//       bg={bg}
//       marginBottom="20px"
//       boxShadow="md"
//       onClick={() => handleSponsoredToolClick(adTool._id)}
//       style={{
//         gridColumn: "1 / -1", // Spans full width of grid
//         background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
//       }}
//       _hover={{ boxShadow: "lg" }}
//       _focus={{ boxShadow: "lg" }}
//     >
//       {/* Gradient Highlight */}
//       <Box
//         position="absolute"
//         top="0"
//         left="0"
//         bottom="0"
//         width="4px"
//         bg="linear-gradient(to bottom, lightblue, pink)"
//         borderRadius="8px 0 0 8px"
//       />

//       {/* Sponsored Label */}
//       <Box
//         position="absolute"
//         top="8px"
//         right="8px"
//         background="rgba(0, 0, 0, 0.7)"
//         color="#ffffff"
//         fontSize="12px"
//         fontWeight="bold"
//         borderRadius="4px"
//         padding="4px 6px"
//       >
//         Sponsored
//       </Box>

//       {/* Ad Content */}
//       <Flex alignItems="center" gap={4}>
//         {/* Ad Logo */}
//         <Image
//   src={adTool.Logo}
//   alt={`${adTool.Title} logo`}
//   borderRadius="5px"
//   objectFit="cover"
//   height={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
//   width={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
// />

//         {/* Title and Tagline */}
//         <Flex flexDirection="column" color={heading} flex="1">
//           <Text fontSize="lg" fontWeight="bold">
//             {adTool.Title}
//           </Text>
//           {/* <Text fontSize="sm" color={font}>
//             {adTool.Tagline}
//           </Text> */}
//            <Text fontSize="sm" color={font}>
//               {isMobile && adTool?.Tagline              
//               ? `${adTool.Tagline.substring(0, 70)}...`
//               : adTool?.Tagline}
//             </Text>
//         </Flex>
//       </Flex>
//     </Box>
//   );
// };

// export default AdBanner;


























import React, { useEffect, useState } from "react";
import { Box, Flex, Text, useColorModeValue, Image, useBreakpointValue } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const AdBanner = () => {
  const [adTool, setAdTool] = useState(null); // Store the ad tool
  const bg = useColorModeValue("#ffffff", "#222222");
  const font = useColorModeValue("grey.700", "grey.400");
  const heading = useColorModeValue("grey.800", "#eeeeee");
  const location = useLocation(); // Track current route

  // Fetch a random tool
  const fetchRandomTool = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
      const tools = response.data;

      if (tools.length > 0) {
        const randomTool = tools[Math.floor(Math.random() * tools.length)]; // Get a random tool
        setAdTool(randomTool);
      }
    } catch (error) {
      console.error("Error fetching sponsored tools:", error);
    }
  };

  // Handle Sponsored Tool Click
  const handleSponsoredToolClick = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
    } catch (error) {
      console.error("Error incrementing click count:", error);
    }
  };

  // Handle Sponsored Tool Impression
  const handleSponsoredToolImpression = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
    } catch (error) {
      console.error("Error incrementing impression count:", error);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Fetch the ad tool when the component mounts or when the route changes
  useEffect(() => {
    fetchRandomTool();
  }, [location]); // Trigger fetch when the location changes

  // // Trigger impression when the ad is loaded
  useEffect(() => {
    if (adTool?._id) {
      handleSponsoredToolImpression(adTool._id);
    }
  }, [adTool?._id]); // Run when `adTool` changes

  // If no ad data is available, render nothing
  if (!adTool) return null;

  return (
    <Box
      as="a"
      href={adTool.finalURL || "#"}
      target="_blank"
      
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid"
      borderColor={font}
      borderRadius="8px"
      padding="16px"
      bg={bg}
      marginBottom="20px"
      boxShadow="md"
      onClick={() => handleSponsoredToolClick(adTool._id)}
      style={{
        gridColumn: "1 / -1", // Spans full width of grid
        background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
      }}
      _hover={{ boxShadow: "lg" }}
      _focus={{ boxShadow: "lg" }}
    >
      {/* Gradient Highlight */}
      <Box
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        width="4px"
        bg="linear-gradient(to bottom, lightblue, pink)"
        borderRadius="8px 0 0 8px"
      />

      {/* Sponsored Label */}
      <Box
        position="absolute"
        top="8px"
        right="8px"
        background="rgba(0, 0, 0, 0.7)"
        color="#ffffff"
        fontSize="12px"
        fontWeight="bold"
        borderRadius="4px"
        padding="4px 6px"
      >
        Sponsored
      </Box>

      {/* Ad Content */}
      <Flex alignItems="center" gap={4}>
        {/* Ad Logo */}
        <Image
          src={`${adTool.Logo}?height=80`}
          alt={`${adTool.Title} logo`}
          borderRadius="5px"
          objectFit="cover"
          height={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
          width={{ base: "60px", md: "80px" }} // Mobile: 60px, Desktop: 80px
        />

        {/* Title and Tagline */}
        <Flex flexDirection="column" color={heading} flex="1">
          <Text fontSize="lg" fontWeight="bold">
            {adTool.Title}
          </Text>
          <Text fontSize="sm" color={font}>
            {isMobile && adTool?.Tagline
              ? `${adTool.Tagline.substring(0, 70)}...`
              : adTool?.Tagline}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AdBanner;
