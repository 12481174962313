import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import style from "../Style/Featured.module.css";
import { RiShareBoxFill } from 'react-icons/ri';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const Tranding = () => {
    let [data, setData] = useState([]);
    //const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const borderColor = useColorModeValue("#e0e0e0", "#444"); // Call this hook at the top level

    const heading = useColorModeValue("gray.800", "#eeeeee");
    const font = useColorModeValue("gray.600", "gray.400");
    const bg = useColorModeValue('gray.50', 'gray.700');

    // const getData = async () => {

    //     // if (isMobile) {
    //     //     // Don't fetch data if on a mobile device
    //     //     return;
    //     // }
    //     try {
    //         let res = await axios.get(`${process.env.REACT_APP_API}/data/top-weekly-score-tools`);
    //         setData(res.data.data);
    //     } catch (err) {
    //         // Handle error
    //     }
    // };


        // Fetch trending tools excluding NSFW
    const getData = async () => {
            try {
                let res = await axios.get(`${process.env.REACT_APP_API}/data/top-weekly-score-tools`);
                // Exclude tools with NSFW category
                const filteredData = res.data.data.filter(tool => !tool.Category?.includes("NSFW"));
                setData(filteredData);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(window.innerWidth < 768);
    //     };

    //     window.addEventListener("resize", handleResize);
    //     handleResize(); // Initial call

    //     return () => window.removeEventListener("resize", handleResize);
    //     }, []);


    useEffect(() => {
        getData();
    }, []); // Fetch data on component mount

    // useEffect(() => {
    //     getData();
    // }, [isMobile]); // Depend on isMobile to fetch data

    // if (isMobile) {
    //     // Don't render anything if on a mobile device
    //     return null;
    // }


    return (
        <div>
            <Box className={style.featured}>
                <Box>
                    <Text 
                        textTransform="uppercase" 
                        fontWeight="700"
                        lineHeight="1.4"
                        fontSize="13px"
                        color={heading}
                        as="h3"
                        mb="20px"
                        letterSpacing="0.5px"
                    >
                        Trending
                    </Text>
                    {
                        data?.slice(0, 6).map((el, i) => (
                            <Box 
                                key={i} 
                                mb="20px" 
                                py="5px" 
                                borderRadius="8px" 
                                // _hover={{ bg }}
                                transition="background 0.2s"
                            >
                                <Flex alignItems="center" gap="15px">
                                    <Link to={`/tool/${el.slug}`}>
                                        <Box 
                                            boxSize="48px"
                                            borderRadius="8px"
                                            overflow="hidden"
                                        >
                                            <Image 
                                                boxSize="100%" 
                                                alt={`${el.Title} logo`}
                                                objectFit="contain"
                                                src={`${el?.Logo}?height=48`}
                                            />
                                        </Box>
                                    </Link>
                                    <Box flex="1">
                                        <Flex alignItems="center" justifyContent="space-between" mb="4px">
                                            <Link to={`/tool/${el.slug}`}>
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                    color={heading}
                                                    lineHeight="1.4"
                                                    // _hover={{ color: "#3B89B6" }}
                                                    as="h1"
                                                >
                                                    {el.Title}
                                                </Text>
                                            </Link>
                                            <Link 
                                                to={`${el?.URL}${el?.URL.includes('?') ? '&' : '?'}utm_source=aizones&utm_medium=referral`} 
                                                target="_blank" 
                                                aria-label="View Product Page"
                                            >
                                                <RiShareBoxFill 
                                                    size={16} 
                                                    aria-label="View Product Page"
                                                    style={{ color: '#3B89B6' }}
                                                />
                                            </Link>
                                        </Flex>
                                        <Link to={`/tool/${el.slug}`}>
                                            <Text
                                                fontSize="13px"
                                                lineHeight="1.5"
                                                className={style.desc}
                                                color={font}
                                                noOfLines={2}
                                                as="h2"
                                            >
                                                {el.Tagline}
                                            </Text>
                                        </Link>
                                    </Box>
                                </Flex>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </div>
    );
};
