// import { SearchIcon } from "@chakra-ui/icons";
// import {
//   Avatar,
//   Box,
//   Center,
//   Flex,
//   Grid,
//   Heading,
//   Image,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   Text,
//   useColorMode,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import style from "../../Style/Collection.module.css";
// import { Link } from "react-router-dom";
// import axios from "axios";
// // import textStyle from "../../Style/List.module.css";
// import LoadingAnimation from '../LoadingAnimation';

// export const Curated = () => {
//   let [data, setData] = useState([]);
//   let [page, setPage] = useState(1);
//   let [find, setfind] = useState("");
//   const { colorMode, toggleColorMode } = useColorMode();
//   const [loading, setLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const borderColor = useColorModeValue("#e0e0e0", "#444");

//   const font = useColorModeValue("gray.600", "gray.400");
//   const heading = useColorModeValue("gray.800", "#eeeeee");

//   const getData = async () => {
//     setLoading(true);
  
//     try {
//       const res = await axios.get(
//         `${process.env.REACT_APP_API}/space/get/All?search=${find}&page=${page}`
//       );
  
//       const fetchedData = res.data.data.space;
//       console.log('Fetched Data:', fetchedData);
  
//       const newData = fetchedData.filter(fetchedItem => 
//         !data.some(existingItem => existingItem._id === fetchedItem._id)
//       );
  
//       console.log('New Data (filtered):', newData);
//       setData(prevData => [...prevData, ...newData]);
//       setHasMore(fetchedData.length > 0);
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };
  
  

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop + 1 < document.documentElement.scrollHeight || !hasMore) {
//         return;
//       }
//       setPage(prev => prev + 1);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [hasMore]);

//   useEffect(() => {
//     getData();
//   }, [page]);

//   useEffect(() => {
//     setPage(1); // Reset to first page
//     setData([]); // Clear current data
//     getData(); // Fetch new data based on search term
//   }, [find]);


//   // if (loading) {
//   //   return (
//   //     <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//   //       <LoadingAnimation size="xl" />
//   //     </div>
//   //   );
//   // }

//   return (
//     <Box fontFamily="'Lato', Arial, Helvetica, sans-serif">
//       <Text as="h2"textAlign={{ base: "left", md: "justify" }} className={style.colldesc} fontSize={{ base: "16px", md: "20px" }}mb="13px" py= "10px" color={heading} >
//       Explore Top AI Tools: Ultimate Collections for Developers & Creatives
//       </Text>

      
//       <InputGroup w={{ base: "100%", md: "45%" }} alignItems="center">
//         <InputLeftElement pointerEvents="none">
//           <SearchIcon color="gray.300" />
//         </InputLeftElement>
//         <Input
//           onChange={(e) => setfind(e.target.value)}
//           border="1px"
//           borderColor={borderColor}
//           placeholder="Search"
//         />
//       </InputGroup>
  

//       <Grid
//         width={"100%"}
//         gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
//         gridTemplateRows={"auto"}
//         columnGap={"50px"}
//         rowGap={"30px"}
//         marginTop={{ base: 7, md: 10 }}
//       >
//         {data?.map((el, i) => (
//           <Link to={`/collection/curated-collection/${el.slug}`} key={i}>
//            <Flex 
//              width={"100%"} 
//                gap={5} 
//                  _hover={{ 
//                "& .hover-grid": { // Targeting the Grid within Flex
//                 borderColor: '#42a4ff',
//                 boxShadow: '0 0 10px #42a4ff'
//                   }
//                 }}
//                 >
//             <Grid
//           width="100px"
//           gridTemplateColumns={"repeat(2,1fr)"}
//           gridTemplateRows={"repeat(2, 1fr)"} // Make it a 2x2 grid for symmetry
//           gap={3}
//           backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"}
//           borderRadius={5}
//           padding={4}
//           height={"100px"}
//           //boxShadow={"0px 3px 6px rgba(0,0,0,0.16)"} // Subtle shadow for depth
//           className="hover-grid"
//         >
//                 {el?.tool[0]?.logoURL ? (
//                   <Image
//                     src={`${el?.tool[0].logoURL}?height=50`}
//                     borderRadius="4px"
//                     width={"40px"}
//                     height={"auto"}
//                     alt={`1st Tool's logo from collection`}
//                   />
//                 ) : (
//                   // <Image
//                   //   src={"https://www.svgrepo.com/show/530661/genetic-data.svg"}
//                   //   borderRadius="4px"
//                   //   width={"40px"}
//                   //   height={"auto"}
//                   // />
//                   ""
//                 )}
//                 {el?.tool[1]?.logoURL ? (
//                   <Image
//                     src={`${el?.tool[1].logoURL}?height=50`}
//                     borderRadius="4px"
//                     width={"40px"}
//                     height={"auto"}
//                     alt={`2nd Tool's logo from collection`}
//                   />
//                 ) : (
//                   // <Image
//                   //   src={
//                   //     "https://www.svgrepo.com/show/530439/api-interface.svg"
//                   //   }
//                   //   borderRadius="4px"
//                   //   width={"40px"}
//                   //   height={"auto"}
//                   // />
//                   ""
//                 )}
//                 {el?.tool[2]?.logoURL ? (
//                   <Image
//                     src={`${el?.tool[2].logoURL}?height=50`}
//                     borderRadius="4px"
//                     width={"40px"}
//                     height={"auto"}
//                     alt={`3rd Tool's logo from collection`}
//                   />
//                 ) : (
//                   // <Image
//                   //   src={
//                   //     "https://www.svgrepo.com/show/530401/table-of-contents.svg"
//                   //   }
//                   //   borderRadius="4px"
//                   //   width={"40px"}
//                   //   height={"auto"}
//                   // />
//                   ""
//                 )}
//                 {el?.tool[3]?.logoURL ? (
//                   <Image
//                     src={`${el?.tool[3].logoURL}?height=50`}
//                     borderRadius="4px"
//                     width={"40px"}
//                     height={"auto"}
//                     alt={`4th Tool's logo from collection`}
//                   />
//                 ) : 
//                   // <Image
//                   //   src={"https://www.svgrepo.com/show/530225/cell-phone.svg"}
//                   //   borderRadius="4px"
//                   //   width={"40px"}
//                   //   height={"auto"}
//                   // />
//                   ""
//                 }
       
//               </Grid>

              

//               <Flex
//                 width={{ base: "70%", md: "80%", lg: "70%" }}
//                 flexDirection={"column"}
//                 gap={2}
//                 alignItems={"flex-start"}
//               >
//                 <Box>
//                   <Text
//                     fontSize={{ base: "15px", md: "15px" }}
//                     fontWeight={600}
//                     color={heading}
//                   >
//                     {el?.space_name}
//                   </Text>
//                 </Box>

//                 <Flex
//                   alignItems={{ base: "flex-start", md: "center" }}
//                   flexDirection={{ base: "column", md: "row" }}
//                 >
//                   {/* <Box paddingRight={2}>
//                     <Text
//                       fontSize={{ base: "12px", md: "15px" }}
//                       color={"#666666"}
//                       fontWeight={600}
//                     >
//                       Created by
//                     </Text>
//                   </Box> */}

//                   <Flex alignItems={"center"}>
//                     <Flex alignItems={"center"} gap={2} >
//                       <Box >
//                       <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}  src={el?.userID?.image } />
//                       </Box>
//                       <Text
//                         fontSize={{ base: "12px", md: "12px" }}
//                         fontWeight={600}
//                       >
//                         {`${el?.userID?.name}`.length > 8
//                           ? `${el?.userID?.name}`.substring(0, 7) + ".."
//                           : `${el?.userID?.name}`}
//                       </Text>
//                     </Flex>
//                     <Text
//                         fontSize={{ base: "12px", md: "12px" }}
//                         fontWeight={600}
//                         px={1}
//                         color={"#3b89b6"}
//                       >-</Text>

                    
//                       <Text
//                         fontSize={{ base: "12px", md: "12px" }}
//                         fontWeight={600}
//                         color={"#3b89b6"}
//                       >
//                         {el?.tool.length}{" "}tools 
//                       </Text>
//                   </Flex>
//                 </Flex>

//                 <Box display={{ base: "block", md: "none" }}>
//                   <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
//                     {`${el?.description}`.length > 30
//                       ? `${el?.description}`.substring(0, 30) + "..."
//                       : `${el?.description}`}
//                   </Text>
//                 </Box>

//                 <Box display={{ base: "none", md: "block" }}>
//                   <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
//                     {`${el?.description}`.length > 55
//                       ? `${el?.description}`.substring(0, 55) + "..."
//                       : `${el?.description}`}
//                   </Text>
//                 </Box>
//               </Flex>        
          
//             </Flex>
            
//           </Link>
//         ))}
              
//         {loading && (
//           <Flex justifyContent="center" width="100%">
//             <LoadingAnimation size="xl" />
//           </Flex>
//         )}
//       </Grid>
//     </Box>
//   );
// };


import { SearchIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import LoadingAnimation from '../LoadingAnimation';

export const Curated = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [find, setfind] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Move color mode values outside of render
  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("white", "#222222");
  const borderColorValue = useColorModeValue("gray.200", "gray.700");
  const hoverBorderColor = useColorModeValue("gray.300", "gray.600");
  const iconGridBg = useColorModeValue("#DAE7FA", "#2f3f48");

  // Add these new color mode values
  const cardShadowLight = "0 4px 12px rgba(66, 153, 225, 0.15)";
  const cardShadowDark = "0 4px 12px rgba(66, 153, 225, 0.25)";
  const cardShadow = useColorModeValue(cardShadowLight, cardShadowDark);
  const toolsColor = useColorModeValue("blue.500", "blue.400");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");
  const placeholderColor = useColorModeValue("gray.500", "gray.500");

  const getData = async () => {
    setLoading(true);
  
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/space/get/All?search=${find}&page=${page}`
      );
  
      const fetchedData = res.data.data.space;
  
      const newData = fetchedData.filter(fetchedItem => 
        !data.some(existingItem => existingItem._id === fetchedItem._id)
      );
  
      setData(prevData => [...prevData, ...newData]);
      setHasMore(fetchedData.length > 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 < document.documentElement.scrollHeight || !hasMore) {
        return;
      }
      setPage(prev => prev + 1);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  useEffect(() => {
    getData();
  }, [page, find]); // Added getData dependency

  useEffect(() => {
    setPage(1);
    setData([]);
  }, [find]);

  // Memoize the card rendering function
  const renderCard = useMemo(() => (el, i) => (
    <Link to={`/collection/curated-collection/${el.slug}`} key={i}>
      <Box
        bg={bgColor}
        borderRadius="lg"
          border="1px"
        borderColor={borderColorValue}
        transition="all 0.2s"
                 _hover={{ 
          borderColor: "blue.400",
          transform: "translateY(-2px)",
          boxShadow: cardShadow
        }}
      >
        <Box p={6}>
          <Box
            width="100%"
            bg={iconGridBg}
          borderRadius={5}
            p={4}
            mb={4}
          >
<Flex 
  gap={3} 
  flexWrap="nowrap"
  justifyContent="space-between"
  width="100%"
  overflow="hidden"
>
  {[0, 1, 2, 3, 4, 5].map((index) => (
    el?.tool[index]?.logoURL && (
      <Box
        key={index}
        display={{ base: index > 3 ? 'none' : 'block', md: 'block' }}
        flexShrink={0}  // Prevents icons from shrinking
      >
        <Image
          src={`${el.tool[index].logoURL}?height=30`}
          borderRadius="4px"
          width="40px"
          height="40px"
          objectFit="contain"
          alt={`Tool ${index + 1} logo`}
        />
      </Box>
    )
  ))}
</Flex>
          </Box>

                  <Text
            fontSize="lg"
            fontWeight="600"
                    color={heading}
            mb={2}
            noOfLines={1}
                  >
                    {el?.space_name}
                  </Text>

                    <Text
            color={font}
            fontSize="sm"
            mb={4}
            noOfLines={2}
          >
            {el?.description}
                    </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            borderTop="1px"
            borderColor={borderColorValue}
          >
            <Flex alignItems="center" gap={2}>
              <Avatar
                src={el?.userID?.image}
                size="xs"
              />
              <Text fontSize="sm" color={font}>
                {el?.userID?.name?.length > 15 
                  ? `${el.userID.name.substring(0, 15)}...` 
                  : el.userID.name}
                      </Text>
                    </Flex>
                    <Text
              fontSize="sm"
              color={toolsColor}
            >
              {el?.tool.length} tools
                      </Text>
                  </Flex>
        </Box>
                </Box>
    </Link>
  ), [bgColor, borderColorValue, font, heading, iconGridBg, cardShadow, toolsColor]);

  return (
    <Box fontFamily="'Lato', Arial, Helvetica, sans-serif">
      <Text
        maxW="3xl"
        mb={6}
        color={descriptionColor}
      >
        Explore collections to made by other users
                  </Text>

      <Box position="relative" mb={6}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.500" />
          </InputLeftElement>
          <Input
            onChange={(e) => setfind(e.target.value)}
            placeholder="Search collections..."
            bg={bgColor}
            border="1px"
            borderColor={borderColorValue}
            _focus={{
              borderColor: "blue.400",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)",
            }}
            _hover={{
              borderColor: hoverBorderColor
            }}
            _placeholder={{
              color: placeholderColor
            }}
          />
        </InputGroup>
                </Box>

      <Grid
        templateColumns={{ 
          base: "repeat(1, 1fr)", 
          md: "repeat(2, 1fr)", 
          lg: "repeat(3, 1fr)" 
        }}
        gap={6}
      >
        {data?.map(renderCard)}
      </Grid>
              
        {loading && (
        <Flex justifyContent="center" width="100%" mt={6}>
            <LoadingAnimation size="xl" />
          </Flex>
        )}
    </Box>
  );
};
