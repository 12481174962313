import React, { useEffect, useRef, useState } from "react";
import { Input, InputGroup, InputRightElement,InputLeftElement, Button, IconButton, useColorModeValue } from "@chakra-ui/react";
import LoadingAnimation from '../LoadingAnimation';

import style from "../../Style/Sleder.module.css";
import { Box, Center, Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import robotimg from "../../Utils/3d-casual-life-chatgpt-robot-happy-raising-left-arm-h-400px.webp";
import searhingrobot from"../../Utils/3d-casual-life-chatgpt-robot-holding-loupe-h-300px.webp";
import {
  CloseIcon,

  Search2Icon,

} from "@chakra-ui/icons";

const delay = 3000;

const quotes = [

"AI will change the world more than anything in history." ,
"Automation isn't a replacement of humans, but of mind-numbing behavior." ,
"AI: Not a substitute for human intelligence, a tool to amplify it.",
"35% of companies are using AI, 42% are exploring its implementation.",
"91.5% of leading businesses invest in AI ongoingly.",
"Customer satisfaction to grow 25% by 2023 with AI use.",
"Netflix saves $1 billion per year with AI in content recommendations.",
"38% of employees expect job automation by 2023.",
"AI expected to create 12 million more jobs than it replaces.",
"AI industry needs 97 million specialists by 2025.",
"AI spend in retail to reach $20.05 billion by 2026.",
"Chatbots can reduce customer service costs by 30%.",
"AI market to hit $1,597.1 billion by 2030.",
"Generative AI left the lab, its future direction is uncertain.",
"No AI apps became overnight success despite hype.",
"Frenetic hunt for a popular AI product ongoing.",
"AI models have gender and ethnic biases.",
"Generative models unpredictable, control attempts often quick fixes.",
"AI’s carbon footprint equivalent to fully charging a smartphone.",
"AI existential risk discussions became familiar.",
"Policy proposals include watermarks for AI-generated content.",
"Record lawsuits against AI companies for intellectual property scraping.",
"Should we be excited or scared about AI?",
"Google to Open AI: 300 can win against 10000!",
"Let's celebrate now, till we have time.",
"End game after launching GPT 4.",
"You helped us more than God has helped us!",
"AI will reach human levels by 2029. – Ray Kurzweil",
"AI is the new electricity. – Andrew Ng",
"Where's our AI bill of rights? – Gray Scott",
"80% of enterprises will invest in AI. – Gartner",
"AI creates more wealth than it destroys. – Erik Brynjolfsson",
"AI could add $13 trillion to the economy by 2030. – McKinsey",
"70% think AI will transform business. – Deloitte",
"AI will add $15.7 trillion by 2030. – PwC",
"AI reduces fraud detection time by 90%. – KPMG",
"50% of data analytics will be AI-driven by 2025. – IDC",
"AI healthcare can cut costs by 50%. – Accenture",
"60% of innovators use AI for insights. – MIT Sloan",
"AI in finance will grow to $26.67B by 2026. – MarketsandMarkets",
"AI boosts productivity by up to 40%. – Accenture",
"AI detects 90% of cyber threats. – Capgemini",
"AI could add 45% to global GDP by 2030. – PwC",
"AI marketing boosts engagement by 50%. – Forbes",
"AI reduces road accidents by 90%. – World Economic Forum",
"AI predictive analytics boosts retail by 30%. – IBM",
"AI is the future of growth. – Mark Zuckerberg",
"AI solves big challenges, like the TV remote. – Satya Nadella",
"AI could be the best or worst for humanity. – Stephen Hawking",
"75% will operationalize AI by 2024. – Gartner",
"AI adds 1.2% to annual GDP growth. – McKinsey",
"AI will achieve 14 of 17 UN goals. – PwC",
"AI cuts operating costs by 25%. – Deloitte",
"AI boosts logistics efficiency by 15%. – Boston Consulting Group",
"AI cuts healthcare costs by 20%. – World Economic Forum",
"AI cuts emissions by 30% in energy. – International Energy Agency",
"AI transforms workplaces to virtual reality. – McKinsey",
"AI increases crop yields by 30%. – Forbes",
"AI redefines customer experience. – Accenture",
"AI adds $2.6T to marketing by 2025. – McKinsey",
"AI cuts call center costs by 70%. – IBM",
"AI closes the global skills gap. – World Economic Forum",
"AI creates new business models. – Harvard Business Review",
"AI creates a safer, sustainable world. – PwC",
"AI drives the next wave of innovation. – Boston Consulting Group",
"AI improves weather forecasting by 50%. – Accenture",
"AI reshapes cybersecurity. – McAfee",
"AI personalizes learning outcomes. – World Economic Forum",
"AI powers the next-gen smart cities. – IBM",
"AI reduces global poverty. – United Nations",
"AI revolutionizes tech interactions. – Google",
"AI unlocks big data potential. – Forbes",
"AI helps treat diseases better. – Harvard Medical School",
"AI boosts disaster response. – World Bank",
"AI is central to the 4th industrial revolution. – World Economic Forum",
"AI promotes social and economic equality. – McKinsey Global Institute"
];

function Slideshow({ searchName }) {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef();
  const [randomQuote, setRandomQuote] = useState('');
  const [input, setInput] = useState("");
  const [showBox, setShowBox] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const heading = useColorModeValue("grey.800", "#eeeeee");

  const phrases = [
    "Discover AI Tools Using AI",
    "Can you suggest tools for digital marketing?",
    "Show me AI tools for marketing",
    "I want help with my emails",
    "I want help in making reels"
  ];
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [placeholderText, setPlaceholderText] = useState(phrases[0]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (placeholderIndex + 1) % phrases.length;
      setPlaceholderIndex(newIndex);
      setPlaceholderText(phrases[newIndex]);
    }, 10000); // Change the interval time (in milliseconds) as needed
  
    return () => clearInterval(interval);
  }, [placeholderIndex, phrases]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  let [data, setData] = useState([]);

  const getData = async () => {
    // try {
    //   const response = await axios.get(`${process.env.REACT_APP_API}/carousel/get`);
    //   setData(response.data.data);
    // } catch (err) {
    //   console.error("Error fetching data:", err);
    // }
  };

  useEffect(() => {
    // Select a random quote
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);

    getData(); // Fetch data when component mounts

    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [searchName]); 

  const { colorMode } = useColorMode(); // Get the current color mode

  const handleInputChange = (e) => {
    setInput(e.target.value); // Update the input state with the new value
    setShowBox(false);

    if (e.key === 'Enter') {
        handleSearchWithCurrentInput(e.target.value); // Trigger search on Enter key press
    }
};

const handleClearInput = () => {
  setInput(""); // Clear the input field
  // Additional actions if needed, like resetting search results
};


const handleSearchWithCurrentInput = (inputValue) => {
  if (inputValue.trim() === "") {
    console.log("Please enter a search query.");
    return;
  }
  
  // Format the inputValue as a slug
  const slug = inputValue.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  
  setIsLoading(true); // Assuming you want to indicate loading state

  // Navigate using the slug
  navigate(`/search/${slug}`);
  
  setIsLoading(false); // You might actually want to set this after the navigation completes and data is loaded
};


const [sponsoredTool, setSponsoredTool] = useState(null); // To store the random sponsored tool

// Function to handle click on sponsored tool URL
const handleSponsoredToolClick = async (id) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
  } catch (error) {
    console.error("Error incrementing click count:", error);
  }
};

// Function to handle impression of sponsored tool
const handleSponsoredToolImpression = async (id) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
  } catch (error) {
    console.error("Error incrementing impressions count:", error);
  }
};

// Fetch Sponsored Tools
useEffect(() => {
  const fetchSponsoredTools = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
      const tools = response.data;

      if (tools.length > 0) {
        const randomIndex = Math.floor(Math.random() * tools.length); // Select a random tool
        const selectedTool = tools[randomIndex];
        setSponsoredTool(selectedTool);

        // Record the impression for the selected tool
        // await handleSponsoredToolImpression(selectedTool._id);
      }
    } catch (error) {
      console.error("Error fetching sponsored tools:", error);
    }
  };

  fetchSponsoredTools();
}, []);

   return (
    
  
  <>
<Flex direction="column" alignItems="center">
  {/* Main Container */}
  <Box className={style.slider} width="100%">
    <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
      
      {/* Main Content Box */}
      <Box
        width="100%"
        mt={{ base: "-30px", md: "-40px" }}
        position="relative"
        textAlign="center"
        py={5} // Padding for vertical spacing
      >
        
        {/* Display Random Quote */}
        <Text
          color="blue.400"
          fontSize={{ base: "14px", md: "16px" }}
          py={3}
          minHeight="40px"
        >
          "{randomQuote}"
        </Text>

        {/* Main Heading - H1 for SEO and Accessibility */}
        {/* <Text
          as="h1"
          fontSize={{ base: "32px", md: "52px", lg: "62px" }}
          lineHeight="shorter"
          mb={4}
          color={colorMode === "light" ? "gray.600" : "gray.200"}
          textAlign="center"
          style={{
            fontFamily: "Nunito, Arial, sans-serif", // Add fallback fonts
          }}
        >
          AI Tools For Everyone.
        </Text> */}
<Box
  width="100%"
  mt={{ base: "-18px", md: "-18px" }}
  position="relative"
  textAlign="center"
  py={4} // Padding for vertical spacing
>
  {/* Display SVG dynamically based on the color mode */}
  <h1 style={{ margin: 0, padding: 0 }}>
    <img
      src={
        colorMode === "light"
          ? "https://ai-zones.b-cdn.net/Website-utilities%20/AI%20For%20Everyone%20-%20darkk.svg"
          : "https://ai-zones.b-cdn.net/Website-utilities%20/AI%20For%20Everyone%20-%20whitee.svg"
      }
      alt="AI Tools For Everyone"
      style={{
        width: "100%", // Full-width responsiveness
        maxWidth: "600px", // Max width to match text behavior
        height: "auto", // Maintain aspect ratio
        margin: "0 auto", // Center horizontally
      }}
    />
  </h1>
</Box>

        {/* Subtitle with Highlights */}
        <Text
          as="h2"
          py={3}
          fontSize={{ base: "sm", md: "md" }}
          color={colorMode === "light" ? "gray.600" : "gray.400"}
          textAlign="center"
        >
          30,000+ AI Tools • Curated Collections • Top 10 • AI Search
        </Text>

               {/* Sponsor Section */}
               {sponsoredTool && (
                <Text
                  as="h3"
                  py={2}
                  fontSize={{ base: "sm", md: "md" }}
                  textAlign="center"
                  fontWeight="bold"
                  color={heading}
                >
                  Sponsor:{" "}
                  <a
                    href={sponsoredTool.finalURL}
                    target="_blank"
                    
                    style={{
                      color: colorMode === "light" ? "#4299E1" : "#4299E1",
                      textDecoration: "underline",
                      fontWeight:"bold"
                    }}  
                    onClick={() => handleSponsoredToolClick(sponsoredTool._id)} // Record the click
                  >
                    {sponsoredTool.Title}
                  </a>
                </Text>
              )}
      </Box>
    </Flex>


       {/* <Box mb="40px" w="100%"> */}
  {data && data.some(el => el.img) && (
    data.map((el, i) => (
      <Flex key={i} w="100%">
        {i === index && el.img && !searchName && (
          <Box w="100%">
            <Link to={el.url} target="_blank">
              <Image w="100%" borderRadius="5px" src={el.img} alt={`Slide ${i}`} />
            </Link>
          </Box>
        )}
      </Flex>
    ))
  )}
{/* </Box> */}

        

        {data.length > 1 && !searchName && data.some((el) => el.img) && (
          <Box className={style.dots}>
            <Flex
              w="fit-content"
              margin="auto"
              gap="10px"
              bg=""
              className={style.slideshowDots}
            >
              {data.map((_, i) => (
                <div
                  key={i}
                  className={`slideshowDot${index === i ? style.active : ""}`}
                  onClick={() => {
                    setIndex(i);
                  }}
                >
                  <Box
                    cursor="pointer"
                    w="12px"
                    height="12px"
                    border="2px solid white"
                    borderRadius="100%"
                    bg={index === i ? "white" : ""}
                  >
                    {" "}
                  </Box>
                </div>
              ))}
            </Flex>
          
          </Box>
        )}
      </Box>
      </Flex>
      <Flex mt={4} mb={10}justifyContent="center" width="100%">
      <InputGroup
  width={{
    base: "100%", // on very small screen (base), use 100% width                      
    md: "450px", // on medium screen sizes (about 768px), use 312px width
    lg: "450px", // on large screen sizes (about 1100px), use 412px width
    xl: "700px"  // on extra large screens, use 612px width
  }}
  height="50px" 
  borderRadius={10}
 
 // Set the height of the InputGroup
  alignItems="center" // Align items vertically in the center
  boxShadow={useColorModeValue("0px 2px 8px rgba(0, 0, 0, 0.15)", "0px 2px 8px rgba(255, 255, 255, 0.15)")} // Slightly less prominent shadowboxShadow={"0 2px 4px rgba(0,0,0,0.1)"} 
  // border="1px solid"
  //borderColor={useColorModeValue("#e0e0e0", "#444")}
>          
  <Input
    id="searchInput"
    height="50px" // Set the Input height to match InputGroup
    value={input}
    onChange={handleInputChange}
    onKeyDown={handleInputChange}
    bg={useColorModeValue("#ffffff", "#1c1c1c")}
    _placeholder={{
      color: "grey",
      fontSize: "14px",
      lineHeight: "24px",
      position: "relative",
      animation: "typing 10s steps(40) infinite",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }}
    color={useColorModeValue("black", "white")}
    borderRadius="5px"
    textAlign="center"
    placeholder={placeholderText}
  />
  <InputLeftElement
    height="50px" // Set the height to keep it centered
   
  >
    {isLoading ? (
      <LoadingAnimation />
    ) : (
      <IconButton
      aria-label="Search"
      icon={<Search2Icon />}
      onClick={() => handleSearchWithCurrentInput(input)} 
      variant="ghost"
        iconSpacing={2}
         />
          )}
            </InputLeftElement>
  <InputRightElement
    height="50px" // Set the height to keep it centered
  >
    {input && (
      <IconButton
        aria-label="Clear"
        icon={<CloseIcon />}
        onClick={handleClearInput}
        variant="ghost"
      />
    )}
  </InputRightElement>
</InputGroup>
</Flex>
    </>
  );
}

export default Slideshow;
