import React, { useEffect, useRef, useState } from "react";
import style from "../../Style/Sleder.module.css";
import { Box, Flex, Image, Text, Input, Button, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FiShare2, FiEdit2, FiArrowRight } from 'react-icons/fi';
import axios from "axios";

const delay = 3000;

const quotes = [
 "AI will change the world more than anything in history." ,
"Automation isn't a replacement of humans, but of mind-numbing behavior." ,
"AI: Not a substitute for human intelligence, a tool to amplify it.",
"35% of companies are using AI, 42% are exploring its implementation.",
"91.5% of leading businesses invest in AI ongoingly.",
"Customer satisfaction to grow 25% by 2023 with AI use.",
"Netflix saves $1 billion per year with AI in content recommendations.",
"38% of employees expect job automation by 2023.",
"AI expected to create 12 million more jobs than it replaces.",
"AI industry needs 97 million specialists by 2025.",
"AI spend in retail to reach $20.05 billion by 2026.",
"Chatbots can reduce customer service costs by 30%.",
"AI market to hit $1,597.1 billion by 2030.",
"Generative AI left the lab, its future direction is uncertain.",
"No AI apps became overnight success despite hype.",
"Frenetic hunt for a popular AI product ongoing.",
"AI models have gender and ethnic biases.",
"Generative models unpredictable, control attempts often quick fixes.",
"AI’s carbon footprint equivalent to fully charging a smartphone.",
"AI existential risk discussions became familiar.",
"Policy proposals include watermarks for AI-generated content.",
"Record lawsuits against AI companies for intellectual property scraping.",
"Should we be excited or scared about AI?",
"Google to Open AI: 300 can win against 10000!",
"Let's celebrate now, till we have time.",
"End game after launching GPT 4.",
"You helped us more than God has helped us!",
"AI will reach human levels by 2029. – Ray Kurzweil",
"AI is the new electricity. – Andrew Ng",
"Where's our AI bill of rights? – Gray Scott",
"80% of enterprises will invest in AI. – Gartner",
"AI creates more wealth than it destroys. – Erik Brynjolfsson",
"AI could add $13 trillion to the economy by 2030. – McKinsey",
"70% think AI will transform business. – Deloitte",
"AI will add $15.7 trillion by 2030. – PwC",
"AI reduces fraud detection time by 90%. – KPMG",
"50% of data analytics will be AI-driven by 2025. – IDC",
"AI healthcare can cut costs by 50%. – Accenture",
"60% of innovators use AI for insights. – MIT Sloan",
"AI in finance will grow to $26.67B by 2026. – MarketsandMarkets",
"AI boosts productivity by up to 40%. – Accenture",
"AI detects 90% of cyber threats. – Capgemini",
"AI could add 45% to global GDP by 2030. – PwC",
"AI marketing boosts engagement by 50%. – Forbes",
"AI reduces road accidents by 90%. – World Economic Forum",
"AI predictive analytics boosts retail by 30%. – IBM",
"AI is the future of growth. – Mark Zuckerberg",
"AI solves big challenges, like the TV remote. – Satya Nadella",
"AI could be the best or worst for humanity. – Stephen Hawking",
"75% will operationalize AI by 2025. – Gartner",
"AI adds 1.2% to annual GDP growth. – McKinsey",
"AI will achieve 14 of 17 UN goals. – PwC",
"AI cuts operating costs by 25%. – Deloitte",
"AI boosts logistics efficiency by 15%. – Boston Consulting Group",
"AI cuts healthcare costs by 20%. – World Economic Forum",
"AI cuts emissions by 30% in energy. – International Energy Agency",
"AI transforms workplaces to virtual reality. – McKinsey",
"AI increases crop yields by 30%. – Forbes",
"AI redefines customer experience. – Accenture",
"AI adds $2.6T to marketing by 2025. – McKinsey",
"AI cuts call center costs by 70%. – IBM",
"AI closes the global skills gap. – World Economic Forum",
"AI creates new business models. – Harvard Business Review",
"AI creates a safer, sustainable world. – PwC",
"AI drives the next wave of innovation. – Boston Consulting Group",
"AI improves weather forecasting by 50%. – Accenture",
"AI reshapes cybersecurity. – McAfee",
"AI personalizes learning outcomes. – World Economic Forum",
"AI powers the next-gen smart cities. – IBM",
"AI reduces global poverty. – United Nations",
"AI revolutionizes tech interactions. – Google",
"AI unlocks big data potential. – Forbes",
"AI helps treat diseases better. – Harvard Medical School",
"AI boosts disaster response. – World Bank",
"AI is central to the 4th industrial revolution. – World Economic Forum",
"AI promotes social and economic equality. – McKinsey Global Institute"


  // ... (your quotes array)
];

function Slideshow_search({ searchName: initialSearchName }) {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef();
  const [randomQuote, setRandomQuote] = useState('');
  const [searchName, setSearchName] = useState(initialSearchName);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const heading = useColorModeValue("grey.800", "#eeeeee");

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const getData = async () => {
    // try {
    //   const response = await axios.get(`${process.env.REACT_APP_API}/carousel/get`);
    //   setData(response.data.data);
    // } catch (err) {
    //   console.error("Error fetching data:", err);
    // }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);

    getData(); // Fetch data when component mounts

    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }, delay);

    return () => {
      resetTimeout();
    };
  }, []); // Only run on initial mount

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this AI tools on AI Zones!',
        text: 'Here\'s a great AI tool I found:',
        url: window.location.href,
      })
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      alert("Your browser doesn't support the native share feature. Please copy the URL manually.");
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDone = () => {
    setIsEditing(false);
    handleSearchWithCurrentInput(searchName); // Trigger search with the new input
  };

  const handleInputChange = (e) => {
    setSearchName(e.target.value); // Update the searchName state with the new value
  };

  const handleSearchWithCurrentInput = (inputValue) => {
    const slug = inputValue.toLowerCase().split(" ").join("-");
    navigate(`/search/${slug}`);
  };


  
const [sponsoredTool, setSponsoredTool] = useState(null); // To store the random sponsored tool

// Function to handle click on sponsored tool URL
const handleSponsoredToolClick = async (id) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
  } catch (error) {
    console.error("Error incrementing click count:", error);
  }
};

// Function to handle impression of sponsored tool
const handleSponsoredToolImpression = async (id) => {
  try {
    await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
  } catch (error) {
    console.error("Error incrementing impressions count:", error);
  }
};

// Fetch Sponsored Tools
useEffect(() => {
  const fetchSponsoredTools = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
      const tools = response.data;

      if (tools.length > 0) {
        const randomIndex = Math.floor(Math.random() * tools.length); // Select a random tool
        const selectedTool = tools[randomIndex];
        setSponsoredTool(selectedTool);

        // Record the impression for the selected tool
        // await handleSponsoredToolImpression(selectedTool._id);
      }
    } catch (error) {
      console.error("Error fetching sponsored tools:", error);
    }
  };

  fetchSponsoredTools();
}, []);

  return (
    <Flex direction="column" alignItems="center">
      <Box className={style.slider} width="100%">
        <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
          <Box width={{ base: "100%", md: "100%" }} mt={{ base: "-30px", md: "-40px" }} position="relative">
            <Box textAlign="left" px="4" py="5" width="100%">
               {/* Sponsor Section */}
               
              <Text textAlign="Center" color="blue.400" fontSize={{ base: "14px", md: "16px" }} py={3}>
                "{randomQuote}"
              </Text>

              {sponsoredTool && (
                <Text
                  as="h3"
                  py={2}
                  fontSize={{ base: "sm", md: "md" }}
                  textAlign="center"
                  fontWeight="bold"
                  color={heading}
                >
                  Sponsor:{" "}
                  <a
                    href={sponsoredTool.finalURL}
                    target="_blank"
                    
                    style={{
                      color:  "#4299E1",
                      textDecoration: "underline",
                      fontWeight:"bold"
                    }}  
                    onClick={() => handleSponsoredToolClick(sponsoredTool._id)} // Record the click
                  >
                    {sponsoredTool.Title}
                  </a>
                </Text>
              )}
              <Flex direction="column" alignItems={{ base: "Center", md: "Center" }} fontSize="18px">
                <Flex justifyContent="center" alignItems="center">
                  {isEditing ? (
                    <Flex alignItems="center">
                      <Input
                        value={searchName}
                        onChange={handleInputChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearchWithCurrentInput(e.target.value)}
                        maxWidth={{ base: "75%", md: "80%" }}
                        fontSize={{ base: "22px", md: "38px" }}
                        fontWeight="bold"
                        padding="20px"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                      />
                      <Button onClick={handleDone} ml={2}>
                        <FiArrowRight />
                      </Button>
                    </Flex>
                  ) : (
                    <Flex alignItems="center">
                      <Text
                        textAlign="left"
                        maxWidth={{ base: "80%", md: "90%" }}
                        as="h1"
                        mt={{ base: "0px", md: "-10px" }}
                        fontSize={{ base: "22px", md: "38px" }}
                        fontWeight="bold"
                        padding="20px"
                        wordBreak="break-word"
                        overflowWrap="break-word"
                      >
                        {searchName}
                      </Text>
                      <Button onClick={handleEdit} ml={2}>
                        <FiEdit2 />
                      </Button>
                    </Flex>
                  )}
                </Flex>
                <Button leftIcon={<FiShare2 />} onClick={handleShare} marginTop="8px">
                  Share
                </Button>
              </Flex>
            </Box>
          </Box>
        </Flex>

        {data && data.some(el => el.img) && (
          data.map((el, i) => (
            <Flex key={i} w="100%">
              {i === index && el.img && !searchName && (
                <Box w="100%">
                  <Link to={el.url} target="_blank">
                    <Image w="100%" borderRadius="5px" src={el.img} alt={`Slide ${i}`} />
                  </Link>
                </Box>
              )}
            </Flex>
          ))
        )}

        {data.length > 1 && !searchName && data.some((el) => el.img) && (
          <Box className={style.dots}>
            <Flex
              w="fit-content"
              margin="auto"
              gap="10px"
              bg=""
              className={style.slideshowDots}
            >
              {data.map((_, i) => (
                <div
                  key={i}
                  className={`slideshowDot${index === i ? style.active : ""}`}
                  onClick={() => {
                    setIndex(i);
                  }}
                >
                  <Box
                    cursor="pointer"
                    w="12px"
                    height="12px"
                    border="2px solid white"
                    borderRadius="100%"
                    bg={index === i ? "white" : ""}
                  >
                    {" "}
                  </Box>
                </div>
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
}

export default Slideshow_search;