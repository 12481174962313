import React, { useEffect, useState, useRef} from "react";
import style from "../../Style/Grid.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  List, ListItem, Switch, FormLabel, useColorMode
} from "@chakra-ui/react";
import { setMyspaceName } from "../../Redux/action";
import { CheckIcon } from "@chakra-ui/icons";
import { HiOutlineBookmark } from "react-icons/hi";
import notification from "../Toast";
import LoginModel from "../model/LoginModel";
import { blue } from "@mui/material/colors";
import HandleGoogle from '../../Firebase/handleGoogleAuth';
import { ArrowBackIcon } from "@chakra-ui/icons";


const Save = ({ id, el, width="" }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputName, setInputName] = useState(false);
  const [newLibrary, setNewLibrary] = useState("");
  const [tagline, setTagline] = useState("");
  const [loading, setloading] = useState(false);
  const [allLibrary, setAllLibrary] = useState([]);
  const userData = useSelector((state) => state.userReducer.loginData);
  const collectionData = useSelector((state) => state.spaceReducer.collectionItems);
  const [reload, setreload] = useState(true);
  // const [loginModelOpen, setloginModelOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [allLibrary, setAllLibrary] = useState([]);

  const mountedRef = useRef(true);
  const navigate = useNavigate();
  
  

  const [showCreateView, setShowCreateView] = useState(false);
  const [isPublic, setIsPublic] = useState(true); // State to manage the collection privacy setting

  const { colorMode } = useColorMode();
  // const hoverBgColor = colorMode === "light" ? "gray.200" : "gray.600";
  const scrollbarTrackBg = useColorModeValue('gray.100', 'gray.600');
  const scrollbarThumbBg = useColorModeValue('gray.400', 'gray.500');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const buttonHoverBg = useColorModeValue('gray.50', 'gray.700');
  const modalTextColor = useColorModeValue('#2D3748', 'white');
  const modalBgColor = useColorModeValue('white', 'gray.800');



  const handleOpen = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, () => onOpen());
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };


  
  // const handleOpen = () => {
  //   if (!userData) {
  //     setloginModelOpen(true);
  //   } else {
  //     // Call getData when the modal is opened
  //     if (allLibrary.length < 1) {
  //       getData();
  //     }
  //     onOpen();
  //   }
  // };

  

  const handleClose = () =>{
    // setloginModelOpen(false)
  }
  const dispatch = useDispatch();
  const gdataArray = [];
  let token = userData.data || false;

  const handleCreate = async (e) => {
    e.preventDefault();
    setloading(true); // Start loading

    onClose(); // Close the modal immediately when the button is clicked


    try {
      let token = userData.data;
      const payload = {
        space_name: newLibrary,
        description: tagline,
        tools: [{ toolID: el._id, logoURL: el?.Logo }], // Updated structure
        status: isPublic ? "Public" : "Private",
        videoID : el._id,       
      };

      //console.log(payload);

      const url = `${process.env.REACT_APP_API}/space/create`;
      const { data: res } = await axios.post(url, payload, {
        headers: { token },
      });
      setreload(!reload);
      setNewLibrary("");
      setTagline("");
      setIsPublic(false); // Reset the privacy toggle if needed
      notification("success", res.msg);
      setInputName(false);
    } catch (err) {
      notification("error", err.response.data.errors);
    } finally {
      setloading(false); // Stop loading regardless of success or failure
    }
  };

  // const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getData = async () => {
    if (!mountedRef.current) return; // Guard clause to prevent state update if the component is unmounted.
    
    setloading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/space/getForSave`, {
        headers: { token },
      });
      if (mountedRef.current) { // Check again after the async call.
        setAllLibrary(res.data.data);
        dispatch(setMyspaceName(res.data.data));
        // No need for console.log in production, consider removing it
      }
    } catch (error) {
      notification("error", error.response.data.errors);
    } finally {
      if (mountedRef.current) {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    // Cleanup function to set the mounted ref to false when the component unmounts
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isOpen && userData) {
      getData();
    }
    // Ensure the effect runs only when the modal is opened and there's userData
  }, [isOpen, userData]); 

  
  // const handleOpen = () => {
  //   if (!userData) {
  //     setloginModelOpen(true);
  //   } else {
  //     // Call getData when the modal is opened
  //     if (allLibrary.length < 1) {
  //       getData();
  //     }
  //     onOpen();
  //   }
  // };

    const handleLibrarySubmit = async (lid) => {
      if (isSubmitting) {
        return; // Prevent further submissions if one is already in progress
      }

      setIsSubmitting(true); // Start submission
      onClose(); // Close the modal immediately

    try {
      let token = userData.data;

      const payload = {
        videoID: el._id,
        spaceID: lid,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API}/myspace/add`,
        payload,
        { headers: { token } }
      );
      notification("success", res.data.msg);

      const spacePayload = {
        tool: [{ toolID: el._id, logoURL: el?.Logo }] // Updated structure
    };
    
      const url = `${process.env.REACT_APP_API}/space/update/${lid}`;
      const { data: spaceRes } = await axios.patch(url, spacePayload, {
        headers: { token },
      });

      setreload(!reload);
      setNewLibrary("");
      setTagline("");
      // onClose(); // Close the modal after successful addition
    } catch (err) {
      if (err.response && err.response.status === 409) {
        // Specific handling for the case when the tool is already added
        notification("warning", err.response.data.msg);
      } else {
        // Generic error handling
        notification("error", err.response.data.errors);
      }
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };
useEffect(() => {
  setAllLibrary(collectionData ?? []);
}, [collectionData])



  const toggleCollectionPrivacy = () => {
    setIsPublic((prev) => !prev);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
    setNewLibrary("");
    setTagline("");
  };

  return (
    <>
       {/* {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null} */}
 <Button
  onClick={handleOpen}
  px={{ base: 3, md: 6 }} // Padding X: 3 on base (mobile), 'auto' on md (medium screens) and up
  py={{ base: "11px", md: "16px" }} // Padding Y: '11px' on base (mobile), 'auto' on md (medium screens) and up
  border="1px"
  borderColor={useColorModeValue("#e0e0e0", "#444")}
  borderRadius="5px"
  fontSize="14px"
  fontWeight="400"
  gap="5px"
  textAlign="center"
  h={{ base: "auto", md: "29.68px" }} // Height: 'auto' on base (mobile), '29.68px' on md (medium screens) and up
  background="transparent"
  width={width}
 
  // _hover={{
  //   bg: hoverBgColor,
  //   transform: 'scale(1.05)',
  // }}
  // transition="all 0.2s"
>
<HiOutlineBookmark />
<Text display={{ base: "none", md: "block" }}>Collect</Text>
</Button>
      <Modal isCentered blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
        <ModalContent
          color={modalTextColor}
          bg={modalBgColor}
          borderRadius="16px"
          w={{ base: "90%", md: "450px" }}
          position="relative"
          boxShadow="xl"
        >
          <CloseButton
            position="absolute"
            right="16px"
            top="16px"
            onClick={onClose}
            size="md"
            _hover={{
              bg: hoverBgColor,
            }}
          />

          <ModalBody p={6}>
            <Box>
              {/* Header with Avatar and Title */}
              <Flex alignItems="center" gap={4} mb={6}>
                <Avatar src={el?.Logo} size="md" />
                <Text fontSize="18px" fontWeight="600">
                  {el?.Title}
                </Text>
              </Flex>

              {!showCreateView ? (
                <>
                  <Text
                    fontSize="22px"
                    fontWeight="500"
                    mb={4}
                  >
                    Save to
                  </Text>

                  {/* Collections List */}
                  <Box 
                    maxH="350px" 
                    overflowY="auto" 
                    mb={4}
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: scrollbarTrackBg,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: scrollbarThumbBg,
                        borderRadius: '4px',
                      },
                    }}
                  >
                    {loading ? (
                      <Text>Loading...</Text>
                    ) : (
                      <List spacing={2}>
                        {allLibrary?.map((ele, i) => (
                          <Box key={i}>
                            <ListItem
                              p={3}
                              borderRadius="md"
                              transition="all 0.2s"
                              _hover={{
                                bg: hoverBgColor,
                                cursor: "pointer",
                                transform: "translateY(-2px)",
                                boxShadow: "sm",
                              }}
                              onClick={() => handleLibrarySubmit(ele?._id)}
                            >
                              <Flex align="center" justify="space-between">
                                <Text fontSize="16px">
                                  {ele?.space_name}
                                </Text>
                                <Tooltip 
                                  label={ele?.status === "Private" ? "Private Collection" : "Public Collection"} 
                                  fontSize="sm"
                                  hasArrow
                                >
                                  {ele?.status === "Private" ? (
                                    <LockIcon boxSize={4} color="red.400" />
                                  ) : (
                                    <UnlockIcon boxSize={4} color="green.400" />
                                  )}
                                </Tooltip>
                              </Flex>
                            </ListItem>
                            {i < allLibrary.length - 1 && <Divider my={2} />}
                          </Box>
                        ))}
                      </List>
                    )}
                  </Box>

                  {/* Create New Collection Button */}
                  <Button
                    onClick={() => setShowCreateView(true)}
                    variant="outline"
                    width="100%"
                    height="48px"
                    borderRadius="8px"
                    borderWidth="2px"
                    borderStyle="dashed"
                    _hover={{
                      bg: buttonHoverBg,
                    }}
                  >
                    + Create new collection
                  </Button>
                </>
              ) : (
                // Create New Collection Form
                <Box>
                  <Button
                    onClick={handleBackClick}
                    leftIcon={<ArrowBackIcon />}
                    variant="ghost"
                    mb={4}
                    _hover={{
                      bg: hoverBgColor,
                    }}
                  >
                    Back
                  </Button>
                  
                  <Input
                    mt={4}
                    type="text"
                    placeholder="Collection Title"
                    value={newLibrary}
                    onChange={(e) => setNewLibrary(e.target.value)}
                    height="48px"
                    borderRadius="8px"
                  />
                  
                  <Textarea
                    mt={4}
                    placeholder="Collection Description"
                    value={tagline}
                    onChange={(e) => setTagline(e.target.value)}
                    borderRadius="8px"
                    resize="vertical"
                    minH="100px"
                  />
                  
                  <Flex align="center" mt={4}>
                    <Switch
                      id="public-switch"
                      isChecked={isPublic}
                      onChange={toggleCollectionPrivacy}
                      colorScheme="green"
                    />
                    <FormLabel htmlFor="public-switch" ml={3} mb={0}>
                      {isPublic ? "Public Collection" : "Private Collection"}
                    </FormLabel>
                  </Flex>
                  
                  <Button
                    mt={6}
                    colorScheme="blue"
                    onClick={handleCreate}
                    isLoading={loading}
                    loadingText="Creating"
                    width="100%"
                    height="48px"
                    borderRadius="8px"
                  >
                    Create Collection
                  </Button>
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};


export default Save;
