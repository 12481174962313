// import { Box } from "@mui/material";
// import React, { useEffect, useState } from "react";


// import style from "../../Style/Grid.module.css";

// import { CircularProgress, Stack } from "@chakra-ui/react";

// import { Modalcomp } from "../Modal";

// import axios from "axios";
// import { setMyspaceName } from "../../Redux/action";
// import { useDispatch, useSelector } from "react-redux";
// import LoadingAnimation from '../LoadingAnimation';

// export const GridPage = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
// }) => {
// const [allLibrary, setAllLibrary] = useState([]);
//   const userData = useSelector((state) => state.userReducer.loginData);
//   const dispatch = useDispatch();

//   let token = userData.data;
//   const getData = async () => {
//     const res = await axios.get(`${process.env.REACT_APP_API}/space/get`, {
//       headers: { token },
//     });

//     setAllLibrary(res.data.data);
//     // setloading(false);
//     dispatch(setMyspaceName(res.data.data));
//     console.log(res.data.data);
//   };
//   useEffect(()=> {
//     token && getData()
//   }, [])

//   return (
//     <Stack w="100%">
//       <Box className={style.gridbox} w="100%">
//         {data
//           ?.filter((e) => e.verify)
//           .map((el, i) => (
//             <Box key={i} >
//               <Modalcomp
//                 el={el}
//                 i={i}
//                 setcat={setcat}
//                 setPageName={setPageName}
//                 setFilterLoader={setFilterLoader}
//               />
//             </Box>
//           ))}
//       </Box>

//       {showLoader ? (
//         <Box
//           height="100px"
//           display={"flex"}
//           alignItems={"center"}
//           textAlign="center"
//           justifyContent={"center"}
//         >
//           <LoadingAnimation size="xl"/>
//         </Box>
//       ) : null}
//     </Stack>
//   );
// };












import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import style from "../../Style/Grid.module.css";
import { CircularProgress, Stack, Flex, Text, Center, useColorModeValue } from "@chakra-ui/react";
import { Modalcomp } from "../Modal";
import axios from "axios";
import { setMyspaceName } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../LoadingAnimation";
import { useLocation } from "react-router-dom";

export const GridPage = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
}) => {
  const [allLibrary, setAllLibrary] = useState([]);
  
  const userData = useSelector((state) => state.userReducer.loginData);
  const dispatch = useDispatch();
  const [sponsoredTools, setSponsoredTools] = useState([]); // Store all active sponsored tools
  const currentAdIndex = useRef(0); // Ref to track the current ad index
  const bg = useColorModeValue("#ffffff", "#222222");
  const font = useColorModeValue("grey.700", "grey.400");
  const heading = useColorModeValue("grey.800", "#eeeeee");
  const location = useLocation(); // Get the current location

  let token = userData?.data;

  // Fetch space data
  const getData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/space/get`, {
      headers: { token },
    });
    setAllLibrary(res.data.data);
    dispatch(setMyspaceName(res.data.data));
  };

  useEffect(() => {
    token && getData();
  }, []);

  // Fetch Sponsored Tools data
  // useEffect(() => {
  //   const fetchSponsoredTools = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
  //       setSponsoredTools(response.data); // Store sponsored tools data
  //     } catch (error) {
  //       console.error("Error fetching sponsored tools:", error);
  //     }
  //   };

  //   fetchSponsoredTools();
  // }, []);



      // Fetch Sponsored Tools data from backend (skip if on home page)
      useEffect(() => {
        if (location.pathname !== "/") {
          const fetchSponsoredTools = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
              setSponsoredTools(response.data); // Store data in state
            } catch (error) {
              console.error("Error fetching sponsored tools:", error);
            }
          };
    
          fetchSponsoredTools();
        }
      }, [location.pathname]);


  

  // Get the next ad for rotation
  const getNextAd = () => {
    if (sponsoredTools.length === 0) return null;
    const ad = sponsoredTools[currentAdIndex.current];
    currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length;
    return ad;
  };

  // Handle click on sponsored tool
  const handleSponsoredToolClick = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
    } catch (error) {
      console.error("Error incrementing click count:", error);
    }
  };

  // Handle impression of sponsored tool
  const handleSponsoredToolImpression = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
    } catch (error) {
      console.error("Error incrementing impressions count:", error);
    }
  };

  // Number of tools before an ad banner appears
  const TOOLS_BEFORE_AD = 9;

  return (
    <Stack w="100%">
    <Box className={style.gridbox} w="100%">
      {data
        ?.filter((e) => e.verify)
        .map((el, i) => (
          <React.Fragment key={i}>
            {/* Render Tool */}
            <Box>
              <Modalcomp
                el={el}
                i={i}
                setcat={setcat}
                setPageName={setPageName}
                setFilterLoader={setFilterLoader}
              />
            </Box>
            

            {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools */}
            {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && location.pathname !== "/" && (
              (() => {
                const adTool = getNextAd();
                return (
                  adTool && (
                    <Box
                    as="a"
                    className="ad-banner" // Add a specific class for the ad banner
                    href={adTool.finalURL} // Dynamic URL
                    target="_blank"
                    
                    position="relative"
                    borderColor={font}
                    borderRadius="8px"
                    padding="16px"
                    bg={bg}
                    margin="20px auto"
                    boxShadow="md"
                    style={{
                      width: "100%", // Ensure full width
                      background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
                    }}
                    _hover={{ boxShadow: "lg" }}
                    onClick={() => handleSponsoredToolClick(adTool._id)} // Increment click count
                    onLoad={() => handleSponsoredToolImpression(adTool._id)} // Increment impression
                  >
                    {/* Gradient Highlight */}
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      bottom="0"
                      width="4px"
                      bg="linear-gradient(to bottom, lightblue, pink)"
                      borderRadius="8px 0 0 8px"
                    />
                  
                    {/* Sponsored Label */}
                    <Box
                      position="absolute"
                      top="8px"
                      right="8px"
                      background="rgba(0, 0, 0, 0.7)"
                      color={heading}
                      fontSize="12px"
                      fontWeight="bold"
                      borderRadius="4px"
                      padding="4px 6px"
                    >
                      Sponsored
                    </Box>
                  
                    {/* Ad Content */}
                    <Flex
                      alignItems="center"
                      justifyContent="center" // Center vertically and horizontally
                      flexDirection="column" // Arrange content vertically
                      gap={4}
                      height="100%" // Ensure the Flex container takes full height
                    >
                      {/* Ad Logo */}
                      <img
                        src={`${adTool.Logo}?height=80`}
                        alt={`${adTool.Title} logo`}
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "5px",
                          objectFit: "cover",
                        }}
                      />
                  
                      {/* Title and Tagline */}
                      <Flex flexDirection="column" textAlign="center">
                        <Text fontSize="lg" color={heading} fontWeight="bold">
                          {adTool.Title}
                        </Text>
                        <Text fontSize="sm" color={font}>
                          {adTool.Tagline}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                  
                  )
                );
              })()
            )}
          </React.Fragment>
        ))}
    </Box>

    {showLoader && (
      <Box
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingAnimation size="xl" />
      </Box>
    )}
  </Stack>
);
};