  import {
      Box,
      Flex,
      Text,
      ListItem,
      ListIcon,
      List,
      useColorModeValue,
      Badge,
      Divider,
      Icon,
      Link,
    } from '@chakra-ui/react';
    import { CheckCircleIcon } from "@chakra-ui/icons";
    import { FaArrowUp } from 'react-icons/fa';
    
    // export const PricingForm = ({ tool_own, setTool }) => {
    
    export const PricingForm = ({ submissionFee = 20, discountedFee = 5, tool_own, setTool }) => {
        return (
          <Box>




            {/* Pricing Header */}
            <Text fontSize="22px" fontWeight="600" mb={4}>
              Submission Fee: 
              {/* <Text as="span" fontSize="1.2em" textDecoration="line-through" color="red.500" mx={2}> */}
              <Text as="span" fontSize="1.2em" mx={2}>
                ${submissionFee}
              </Text>
              {/* <Badge colorScheme="green" fontSize="1.2em">${discountedFee}</Badge>  */}
              {/* <Text fontSize="14px" color="gray.500" ml={2} as="span">
                End of the Year Discount
              </Text> */}
            </Text>
      
            {/* Explanation of Pricing */}
            {/* <Text fontSize="16px" fontWeight="500" mb={4}>
              The submission fee is now just $5 as part of our end-of-year celebration! Prices are subject to change with growing community engagement.
            </Text> */}
            <Text fontSize="16px" fontWeight="500" mb={4}>
            The submission fee is a fixed price of $20, set by the growing popularity and engagement of our users.
            Prices may fluctuate with increased popularity.
          </Text>
    
          <Divider mb={4} />
    
          <Text fontSize="20px" fontWeight="600" mb={4}>
            Why Submit to AI Zones?
          </Text>
         
          <List spacing={3}>
          <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Get listed on our homepage
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Indexed on Google
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Free access to a Creator’s Portal to update your tool anytime.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Get listed in the largest AI directory
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Permanent backlink to improve your website's SEO.
            </ListItem>
          </List>
    
          <Box flex="1" bg={useColorModeValue("gray.100", "gray.700")} p={4} borderRadius="lg" textAlign="center" mt={6}>
            <Text fontSize="24px" fontWeight="bold" mb={4}>
              AI Zones is the most vibrant AI community
            </Text>
            <Flex justifyContent="center" alignItems="center">
              <Icon as={FaArrowUp} color="green.500" boxSize={6} mr={2} />
              <Text fontSize="18px" fontWeight="600">1 million + views</Text>
            </Flex>
            <Text fontSize="14px" mt={2} fontWeight="400">
              Join our rapidly growing community!
            </Text>
            <Divider mb={4} mt={4} />
            <Text fontSize="14px" fontWeight="500">
              <Link href="https://www.passionfroot.me/ai-zones" color="teal.500" isExternal>
                Advertise
              </Link>
            </Text>
            <Text fontSize="14px" fontWeight="500">
              <Link href="mailto:hello@aizones.io" color="teal.500">
                Contact Support
              </Link>
            </Text>
          </Box>
        </Box>
      );
    };
    