import React from "react";
import { Route, Routes } from "react-router-dom";
import { Collection } from "./Pages/Collection";
import { SubNavbar } from "./Components/Home/SubNavbar";
import { Tool } from "./Pages/Tool";
import { Top10 } from "./Pages/Top10";
import { CollectionSinglepage } from "./Pages/CollectionSinglepage";
import { Submit } from "./Pages/Submit";
import { Signup } from "./Pages/Signup";
import { Login } from "./Pages/Login";
import { Profile } from "./Pages/Profile";
import Expert  from "./Pages/Influencer";
import { Creaters } from "./Pages/Creaters";
import { MycollectionSingle } from "./Pages/MycollectionSingle";
import { SearchPage} from "./Pages/SearchPage";
import { Privacy } from "./Pages/Privacy";
import { ForgatePassword } from "./Components/Signup/ForgatePassword";
import { LikedCollectionSinglePage } from "./Pages/LikedCollectionSinglePage";
import { MyCollectionSinglePage } from "./Pages/MyCollectionSinglePage";
import { CuratedCollectionsSinglePage } from "./Pages/CuratedCollectionsSinglePage";
import NotFound from "./Pages/NotFound";
import MyTools from "./Pages/MyTools";
import { SearchComp } from "./Components/Home/SearchComp";
import { LandingPage } from "./Pages/LandingPage";
import AboutPage from "./Pages/About";
import BlogPage from "./Pages/Blog_post";
import AdminPage from "./Pages/blogedit";
import RedirectToFinalURL from "./Components/URL_redirect";

import BlogLandingPage from "./Pages/Blog_landingpage";

export const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<SubNavbar />} />
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/updates/:slug" element={<BlogPage />} />
        <Route path="/blogedit" element={<AdminPage />} />
        <Route path="/updates" element={<BlogLandingPage />} />
        {/* <Route path="/search" element={<SubNavbar />} /> */}
        <Route path="/search/:slug" element={<SearchComp />} />
        <Route path="/my-tools" element={<MyTools />} />
        {/* <Route path="/Expertss" element={<Expert />} /> */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/collection/:slug" element={<CollectionSinglepage />} />
        <Route
          path="/collection/curated-collection/:slug"
          element={<CuratedCollectionsSinglePage />}
        />
        <Route
          path="/collection/liked-collection/:slug"
          element={<LikedCollectionSinglePage />}
        />
        <Route
          path="/collection/my-collection/:slug"
          element={<MyCollectionSinglePage />}
        />
        {/* <Route path="/tool/:id" element={<SubNavbar />} /> */}
        <Route path="/tool/:slug" element={<Tool />} />
        <Route path="/top10" element={<Top10 />} />
        <Route path="/submit" element={<Submit />} />
        <Route path="/update-tool/:id" element={<Submit />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile_setting" element={<Profile />} />
        <Route path="/creator" element={<Creaters />} />
        <Route path="/mycollection/:slug" element={<MycollectionSingle />} />
        {/* <Route path="/search" element={<SearchPage />} /> */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/change_password" element={<ForgatePassword />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/tool/undefined" element={<NotFound />} />
        <Route path="/redirect/:id" element={<RedirectToFinalURL />} />
      </Routes>
    </>
  );
};
